import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from "../Common/Heading";
import CarouselContent from "../Common/CarouselContent";

const Associations = props => {
  const { content } = { ...props?.fields };

  const heading = getFieldValue(props.fields, "heading");

  return (
    <Container className="association-container">
      <div className="association-section">
        {<Heading className="association-heading" title={heading} />}
        {content && (
          <CarouselContent
            carouselcontentClassname="association-carousel"
            carouselData={content}
          />
        )}
      </div>
    </Container>
  );
};

export default Associations;

Associations.propTypes = {
  fields: PropTypes.object
};
