import React from "react";
import Heading from "../Common/Heading";
import Langform from '../Common/LanguageAssistanceForm';
import TextComponent from "../Common/TextComponent";
import { markedRequired } from '../Common/LanguageAssistanceForm/Constant';
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const LanguageAssistanceModal = (props) => {
  const formtitle = getFieldValue(props?.fields, 'formtitle');
  const contactnumber = getFieldValue(props?.fields, 'contactnumber');
  const callbackdesc = getFieldValue(props?.fields, 'callbackdesc');
  const { emailInfo } = { ...props?.fields };

  return (
    <div id='contactForm' className='langAssistanceModal' data-testid='getModal'>
      <Heading title={formtitle} className='langTitleModal'></Heading>
      <TextComponent description={contactnumber} className="contactLangAssistanceModal" />
      <TextComponent description={callbackdesc} className="filltLangAssistanceModal" />
      <p className="formMandadoryDesc mt-3">{markedRequired}</p>
      <Langform emailInfo={emailInfo}/>
    </div>
  )
}
export default LanguageAssistanceModal;