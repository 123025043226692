import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import List from "../Common/List/List";

function TwoColumnBlog(props) {
  const { title, content, description } = { ...props?.fields }
  const titleval = title?.value;
  const descriptionval = description?.value;
  return (
    <div id="blog-style" className="blog-style">
      <Container>
        <Row className='section-wrapper'>
          <Col className=' d-flex justify-content-center align-items-center'>
            <Heading title={titleval} className='pageTitle' />
          </Col>

          <Col>
            {description && <TextComponent className="sub-title" description={descriptionval} />}
            <List list={content} listStyle='listStyle' imgStyle='imgStyle' />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TwoColumnBlog;

TwoColumnBlog.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
