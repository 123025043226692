import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";
import { isMobile } from "../Common/utils";
import List from "../Common/List/List";

function CollaborativeCare(props) {
  const { imageUrlWeb, imageUrlMobile, collaborativeCareList, subHeading } = {
    ...props.fields
  };
  const [isMobileView, setIsMobileView] = useState(false);
  const isMobileViewData = isMobile();
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  return (
    <div id="rareDisease" className="rareDisease">
      <Container>
        <Row>
          <Col className="imgBlog-mobile col-lg-5">
            <ImageComponent
              imgUrl={
                isMobileView
                  ? imageUrlMobile?.value?.src
                  : imageUrlWeb?.value?.src
              }
              imgClassname="imgBlog"
            />
          </Col>

          <Col className="col-lg-7">
            <Row>
              <Heading title={subHeading?.value} className="sub-heading" />
            </Row>
            <Row>
              <div className="grid-container">
                <List
                  list={collaborativeCareList}
                  listStyle="listStyle"
                  imgStyle="imgStyle"
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default CollaborativeCare;

CollaborativeCare.propTypes = {
  fields: PropTypes.object
};
