import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import window from "global";
import { usePapaParse } from "react-papaparse";
import { isMobile } from "../Common/utils";
import { csvUrl } from "../Common/constants";

export const BreadcrumbComponent = props => {
  const [isMobileView, setIsMobileView] = useState(false);
  const { content } = { ...props?.fields };
  const isMobileViewData = isMobile();
  const [deseaseTitle, setDeseaseTitle] = useState("");
  const { readRemoteFile } = usePapaParse();
  const pageLocation = props?.sitecoreContext?.route?.name?.trim();
  useEffect(() => {
    if (pageLocation.includes("specialty-condition-details")) {
      let deseaseId;
      readRemoteFile(csvUrl, {
        complete: results => {
          if (!results.errors.length) {
            const allData = results.data;
            if (window?.location?.href) {
              const params = new URL(window?.location?.href).searchParams;
              deseaseId = params.get("deseasId");
            }
            setDeseaseTitle(allData[deseaseId][0]);
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  return (
    <div id="spjm_breadcrumb">
      <Container>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-list">
            {content &&
              content.length > 0 &&
              content.map((breadcumbitem, index) => {
                const titleVal = breadcumbitem?.fields?.title?.value;
                const title = titleVal === "" ? deseaseTitle : titleVal;
                const href = breadcumbitem?.fields?.url?.value?.href;
                return (
                  <li
                    key={`breadcrumb_${index}`}
                    className={`breadcrumb-item ${content.length - 1 ===
                      index && "active breadcrumb-current"}`}
                  >
                    {content.length - 1 === index ? (
                      <span
                        className={`${isMobileView &&
                          content.length > 2 &&
                          "acitve-item-wrapper"}`}
                      >
                        {title}
                      </span>
                    ) : (
                      <a className="breadcrumblink" href={href}>
                        {title}
                      </a>
                    )}
                  </li>
                );
              })}
          </ol>
        </nav>
      </Container>
    </div>
  );
};

export default withSitecoreContext()(BreadcrumbComponent);

BreadcrumbComponent.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
