import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";

const PrPackagingBlog = props => {
  const title = getFieldValue(props?.fields, "title");
  const description = getFieldValue(props?.fields, "description");
  const { content } = { ...props?.fields };
  const themeColor = getFieldValue(props?.fields, "themeColor");
  const themeBg = getFieldValue(props?.fields, "themeBg");
  return (
    <div className="spjm-patient-resource">
      <div className={themeBg}>
        <Container className="section-top section-bottom">
          <Row className="packaging-title-container">
            <div className="packaging-title-wrapper">
              {title && (
                <Heading
                  title={title}
                  className="fa-title packagingBlog-title"
                />
              )}
              {description && (
                <TextComponent
                  className="fv-description"
                  description={description}
                />
              )}
            </div>
          </Row>
          <div className='packingbolg-mobile'>
            {
              content?.length > 0 && content?.map(item => {
                const imgval = item.fields.image.value.src;
                const titleval = item.fields.title.value;
                const descriptionval = item.fields.description.value;
                return (
                  <Row className={themeColor} key={item.id}>
                    <Col className='packaging-blog-img'>
                      <img src={imgval} alt={imgval} className='packagingBlog-row-img' />
                    </Col>
                    <Col className='blog-alignment'>
                      <div>
                        <Heading title={titleval} className='packagingBlog-subHeading' />
                        <TextComponent
                          className="packing-description"
                          description={descriptionval}
                        />
                      </div>
                    </Col>
                  </Row>
                )
              })
            }
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PrPackagingBlog;

PrPackagingBlog.propTypes = {
  fields: PropTypes.object
};
