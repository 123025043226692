import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";

const CapabilitiesTiles = props => {
  const { title, content } = { ...props?.fields };
  return (
    <div className="capabilities-main-container">
      <Container>
        {title && (
          <Heading
            title={title.value}
            className="heading-lbl capabilities-heading"
          />
        )}
        <Row>
          {content?.map((eachTile, index) => {
            const titlevalue = eachTile?.fields?.title?.value;
            const subTitlevalue = eachTile?.fields?.subTitle?.value;
            const imageurlval = eachTile?.fields?.imageUrl?.value?.src;
            return(
            <Col md={3} lg={3} sm={6} xs={6} className="mb-4" key={index}>
              <Col md={12} lg={12} className="tile-main-container">
                <div>
                  <ImageComponent
                    imgUrl={imageurlval}
                  />
                </div>
                <div className="tile-heading">
                  {titlevalue}
                </div>
                <div className="tile-description">
                  {subTitlevalue}
                </div>
              </Col>
            </Col>
          );
        })
      }
        </Row>
      </Container>
    </div>
  );
};

export default CapabilitiesTiles;

CapabilitiesTiles.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
