import React from 'react';
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from '../Common/Heading';
import TextComponent from '../Common/TextComponent';
const Content = (props) => {
    const title = getFieldValue(props?.fields, 'title');
    const description = getFieldValue(props?.fields, 'description');
    return (
      <>
        {title && (<Heading title={title} className='pageTitle pb-4' />)}
        {description && (<TextComponent
            className="sub-description"
            description={description}
          />)}
    </>
    )
  }
  export default Content;