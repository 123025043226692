import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Container } from "react-bootstrap";
import ContactusForm from "../components/ContactusForm";
import { ReactComponent as ContactusThumbsup } from "../assets/icons/contactus_thumbsup.svg";
import { ReactComponent as DownArrow } from "../assets/icons/down_arrow.svg";
import { ReactComponent as UpArrow } from "../assets/icons/up_arrow.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/close_icon.svg";
import { thankyou_text, indication_message } from "./Constants";
import {
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import { getInfoByComponent } from "../components/Common/utils";

const UseModalComponent = (props) => {
  const [message, setMessage] = useState(true);
  const [viewnumberStatus, setViewNumbersStatus] = useState(false);
  const { hidemodal } = props;
  const header = getInfoByComponent(props.sitecoreContext, "Header");
  const contactUsHeader = getFieldValue(header, "contactUsHeader");
  const contactUsDesc = getFieldValue(header, "contactUsDesc");
  const importantMobileNumbers = getFieldValue(
    header,
    "importantMobileNumbers"
  );
  const { anchor, text } = getFieldValue(header, "contactUsLink");
  const contactList = header.contactUsList;
  const reCaptchaKey = getFieldValue(header, "siteKey");
  const languageList = header.language;

  const messageOpen = (val) => {
    setMessage(val);
  };

  const numbersHandleClick = () => {
    setViewNumbersStatus((current) => !current);
  };

  return (
    <Modal id="spjm-modal" {...props}>
      <Row className="cancel-button-container">
        <Col>
          <div className="cancel-button" onClick={() => hidemodal(false)}>
            <CloseIcon />
          </div>
        </Col>
      </Row>

      <Modal.Body>
        {message ? (
          <Container>
            <Row className="flex-lg-row flex-column">
              <Col className="contact-details">
                <div className="modal-title">{contactUsHeader}</div>
                <div className="contact-description ">{contactUsDesc}</div>

                <div className="view-important-numbers-text d-lg-none d-sm-block">
                  <span>{importantMobileNumbers}</span>

                  <span className="arrow-icons" onClick={numbersHandleClick}>
                    {viewnumberStatus ? <UpArrow /> : <DownArrow />}
                  </span>
                </div>

                <div
                  className={
                    !viewnumberStatus
                      ? "contactDetails-mobile-none"
                      : "contactDetails-mobile-block"
                  }
                >
                  {contactList?.map(({ fields = {} }, index) => {
                    return (
                      <div key={index} className="contact-dept-wrapper">
                        <div className="contact-sub-heading">
                          {getFieldValue(fields, "title")}
                        </div>
                        <div
                          className="contact-sub-heading-desc"
                          dangerouslySetInnerHTML={{
                            __html: getFieldValue(fields, "phone"),
                          }}
                        ></div>
                      </div>
                    );
                  })}
                  <br />
                  <br />
                  <a
                    href={anchor}
                    style={{
                      fontWeight: 700,
                      color: "#254067",
                      textDecoration: "none",
                      borderBottom: "1px solid #254067",
                    }}
                  >
                    {text}
                  </a>
                </div>
              </Col>

              <Col className="d-flex justify-content-lg-end justify-content-sm-start ">
                <ContactusForm
                  hidemodal={hidemodal}
                  messageOpen={messageOpen}
                  emailInfo={props?.emailInfo}
                  reCaptchaKey={reCaptchaKey}
                  languageList={languageList}
                />
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <div className="form-submit-message">
              <div>
                <div className="thumbpsup-img-wrapper">
                  <ContactusThumbsup />
                </div>
                <div className="thankyou-text">{thankyou_text}</div>
                <div className="intimation-text">{indication_message}</div>
              </div>
            </div>
          </Container>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default withSitecoreContext()(UseModalComponent);

UseModalComponent.propTypes = {
  emailInfo: PropTypes.object,
  sitecoreContext: PropTypes.object,
};
