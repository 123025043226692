import React from "react";
import PropTypes from "prop-types";

const Heading = props => {
  const { title, className } = props;
  return (
    <div className={`text-center ${className || "heading-lbl"}`}>{title}</div>
  );
};

export default Heading;

Heading.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};
