import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";

const PrMailOrderBlog = props => {
  const title = getFieldValue(props?.fields, "title");
  const description = getFieldValue(props?.fields, "description");
  const subHeading = getFieldValue(props?.fields, "subHeading");
  const subDescription = getFieldValue(props?.fields, "subDescription");
  const contactDetailsTitle = getFieldValue(
    props?.fields,
    "contactDetailsTitle"
  );
  const contactDetails = getFieldValue(props?.fields, "contactDetails");
  return (
    <div className="spjm-patient-resource">
      <Container className="mail-order section-top section-bottom ">
        <Row>
          <Col>
            {title && <Heading title={title} className="fa-title" />}
            {description && (
              <TextComponent
                className="fv-description"
                description={description}
              />
            )}
            {subHeading && (
              <Heading title={subHeading} className="subHeading" />
            )}
            {subDescription && (
              <TextComponent
                className="fv-description"
                description={subDescription}
              />
            )}
          </Col>

          <Col className="contact-details-wrapper">
            <div className="contact-details-conainer">
              {contactDetailsTitle && (
                <Heading
                  title={contactDetailsTitle}
                  className="contact-details-title"
                />
              )}
              {contactDetails && (
                <TextComponent
                  className="contact-details-description"
                  description={contactDetails}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrMailOrderBlog;

PrMailOrderBlog.propTypes = {
  fields: PropTypes.object
};
