/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable camelcase */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Accordion from "../Common/accordion";
import { document_view } from "./constant";

const ImportantResources = props => {
  const { content } = { ...props?.fields };
  {
    content &&
      content.map((items, index) => {
        const accodionDescription = [];

        items.fields.content.map(list => {
          const title = list?.fields?.title?.value;
          const description = list?.fields?.description?.value;
          const listImg = list?.fields?.image?.value?.src;
          const listFile = list?.fields?.pdf?.value?.src;

          const eachAccordionData = `<div class='accordion-sub-content' key=${index}>
        <div>
          <h6 class='resources-menu-title'>${title}</h6>
        </div>
        <div class='container'>
          <div class='row resources-content pb-4'>
            <div class='col-sm-12 col-md-9 px-0'>
              <h6 class='resources-menu-description'>${description}</h6>
            </div>
            <div class='col-sm-12 col-md-3 px-0 documents-section'>
            <a href=${listFile} target='blank' class='document-link'>
              <img src=${listImg} alt='Document Icon' class='mb-1 document-icon'/>
                ${document_view}
            </a>
              </a>
            </div>
          </div>
        </div>
      </div>`;
          accodionDescription.push(eachAccordionData);
        });
        const value = { value: accodionDescription.join("") };
        // eslint-disable-next-line no-param-reassign
        items.fields.description = value;
      });
  }

  return (
    <div className="spjm-important-resources">
      <div className="spjm-patient-resource">
        <Container>
          <Row>
            <Col>
              <div className="financial-advocate-accordion-container">
                <Accordion
                  accordionData={content}
                  className="resources-title"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ImportantResources;

ImportantResources.propTypes = {
  fields: PropTypes.object
};
