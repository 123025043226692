import React, { useState, useRef } from "react";
import { Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import downArrow from "../../assets/icons/blue_down_arrow.svg";
import upArrow from "../../assets/icons/blue_up_arrow.svg";

const PrRecyclingAndReuseBlog = props => {
  // const { title, content, description, image } = props.fields;
  const title = getFieldValue(props?.fields, "title");
  const description = getFieldValue(props?.fields, "description");
  const { content } = { ...props?.fields };
  const [toggleBtn, setToggleBtn] = useState(false);
  const patientResource = useRef(null);

  const handleButtonClick = () => {
    patientResource.current.scrollIntoView({
      behavior: "auto",
      block: "start"
    });
  };
  const viewMoreBtn = () => {
    setToggleBtn(!toggleBtn);
    handleButtonClick();
  };

  return (
    <div className="spjm-patient-resource" ref={patientResource}>
      <div className="polystyrene-blog">
        <Container>
          <Row className="packaging-title-container">
            <div className="packaging-title-wrapper">
              {title && (
                <Heading
                  title={title}
                  className="fa-title packagingBlog-title"
                />
              )}
              {description && (
                <TextComponent
                  className="fv-description"
                  description={description}
                />
              )}
            </div>
          </Row>

          {toggleBtn && (
            <Row>
              {content?.length > 0 &&
                content?.map(item => {
                const titleval = item.fields.title.value;
                const descriptionval = item.fields.description.value;
                  return(
                  <div key={item.title}>
                    <Heading
                      title={titleval}
                      className="recycleblog-subHeading"
                    />
                    <TextComponent
                      className="fv-description"
                      description={descriptionval}
                    />
                  </div>
                )})}
            </Row>
          )}

          <div className="toggle-button-container">
            <button
              className="toggle-button"
              onClick={() => viewMoreBtn()}
              data-testid="toggleBtn"
            >
              {!toggleBtn ? (
                <span>
                  <span>Show more</span>
                  {/* <DownArrow /> */}
                  <img
                    src={downArrow}
                    alt="downarrow"
                    className="recyclingImg"
                  />
                </span>
              ) : (
                <span>
                  <span data-testid="toggleText">Show less</span>
                  {/* <UpArrow /> */}
                  <img src={upArrow} alt="uparrow" className="recyclingImg" />
                </span>
              )}
            </button>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PrRecyclingAndReuseBlog;

PrRecyclingAndReuseBlog.propTypes = {
  fields: PropTypes.object
};
