/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";
import TextComponent from "../Common/TextComponent";
import Accordion from "../Common/accordion";
import { isMobile } from "../Common/utils";

const PatientFinancialAdvocate = props => {
  const { title, pfa_image, description, accordionData } = { ...props?.fields };
  const isMobileView = isMobile();
  const titleval = title?.value;
  const imgval = pfa_image?.value?.src;
  const descriptionval = description?.value;

  return (
    <div className="spjm-patient-resource">
      <Container className="first-section-top">
        <Row>
          <Col>
            {isMobileView && <Heading title={titleval} className='fa-title' />}
            <ImageComponent imgClassname='image-block' imgUrl={imgval} />
          </Col>

          <Col>
            <Row>
              {!isMobileView && <Heading title={titleval} className='fa-title' />}
            </Row>
            <Row id="finance-advocate-content">
              <TextComponent
                className="fv-description"
                description={descriptionval}
              />
              <div
                id="financial-advocate-accordion-container"
                className="financial-advocate-accordion-container"
              >
                <Accordion accordionData={accordionData} />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PatientFinancialAdvocate;

PatientFinancialAdvocate.propTypes = {
  fields: PropTypes.object
};
