import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import CardComponent from "../Common/Card";
import Heading from "../Common/Heading";

const Article = props => {
  const { content } = { ...props?.fields };

  const title = getFieldValue(props.fields, "title");

  return (
    <div id="articleComponent" className="mb-5">
      {title && <Heading title={title} className="article-heading" />}
      {content &&
        content.map((articleList, index) => (
          <div key={`card-${index}`}>
            <CardComponent
              imgClassname={"imgArticleCommon"}
              data={articleList}
            />
          </div>
        ))}
    </div>
  );
};

export default Article;

Article.propTypes = {
  fields: PropTypes.object
};
