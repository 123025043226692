import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";

const PatientCounter = props => {
  const { content, themeColor, textColor } = { ...props?.fields };
  const sectionCounterData = content;
  const badge = ["", "", "", "+"];
  const currentpath = `/${props?.sitecoreContext?.route?.name}`;

  return (
    <div id="counter-container" className="counter-container">
      <div className={`${themeColor?.value}`}>
        <Container>
          <Row className="d-flex justify-content-around">
            {sectionCounterData &&
              sectionCounterData.length > 0 &&
              sectionCounterData.map((item, index) => {
                const { fields } = item;
                const textColorVal = textColor?.value;
                const counterval = fields?.counterValue?.value;
                const imgval = fields?.img?.value?.src;
                const descriptionval = fields?.description?.value;
                return (
                  <Col key={counterval}>
                    <img src={imgval} className="icon-center" />
                    <div className="counter-value-container">
                      <span className={`counter-digit ${textColorVal}`}>
                        {counterval}
                      </span>
                      {
                        <span
                          className={`badgeValue 
                          ${currentpath === "/prescribers" ||
                              currentpath === "/home"
                              ? "d-none"
                              : ""
                            } ${textColorVal}`}
                        >
                          {badge[index]}
                        </span>
                      }
                    </div>
                    <div className={`counter-description ${textColorVal}`}>
                      {descriptionval}
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withSitecoreContext()(PatientCounter);

PatientCounter.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
