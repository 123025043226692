import React, { useState, useEffect } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import window from "global";
import { usePapaParse } from "react-papaparse";
import { csvUrl } from "../Common/constants";
import { isMobile } from "../Common/utils";

const Banner = props => {
  const { bannerImage, bannerImgMobile, subTitle } = { ...props?.fields };
  const { title } = { ...props?.fields };
  const [isMobileView, setIsMobileView] = useState(false);
  const [deseaseTitle, setDeseaseTitle] = useState("");
  const { readRemoteFile } = usePapaParse();
  const isMobileViewData = isMobile();

  const currentPage = props?.sitecoreContext?.route?.name;

  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  useEffect(() => {
    if (title && (!title?.value || title?.value === "")) {
      let deseaseId;
      readRemoteFile(csvUrl, {
        complete: results => {
          if (!results.errors.length) {
            const allData = results.data;
            if (window?.location?.href) {
              const params = new URL(window?.location?.href).searchParams;
              deseaseId = params.get("deseasId");
            }
            setDeseaseTitle(allData[deseaseId][0]);
          }
        }
      });
    }
  }, []);
  const imgUrl = isMobileView
    ? bannerImgMobile?.value?.src
    : bannerImage?.value?.src;
  const titlevalue = title?.value !== "" ? title?.value : deseaseTitle;
  const subtitlevalue = subTitle?.value;
  return (
    <div className="bannerMainDiv">
      {imgUrl && (
        <img
          className={`${imgUrl}`}
          key={`img-content-${imgUrl}`}
          src={imgUrl}
          alt="New Patient"
        />
      )}
      <Container
        className={`container-main ${currentPage === "about-us" &&
          "aboutus-banner"}`}
      >
        {titlevalue && <p className="bannerTitle">{titlevalue}</p>}
        {subTitle && <p className="bannerSubtitle">{subtitlevalue}</p>}
      </Container>
    </div>
  );
};

export default withSitecoreContext()(Banner);

Banner.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
