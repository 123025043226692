export const contactus_description = "Submit a contact request using the form or call one of the numbers below.";
export const important_mobile_numbers = "View important numbers";
export const thankyou_text = "Thank you !";
export const indication_message = "We will contact you soon";
export const contact_us = "Contact Us";
export const specialty_patient = "Specialty Patients";
export const specialty_patient_phone1 = "612-672-5260";
export const specialty_patient_phone2 = "1-800-595-7140";
export const transplant_patients = "Transplant Patients";
export const transplant_patients_phno1 = "612-617-3700";
export const transplant_patients_phno2 = "1-855-257-0942";
export const toll_free = "toll free";