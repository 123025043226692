/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import window from "global";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import LeftPanelContent from "./LeftPanelContent";

const SpecialtyDetailPage = props => {
  const { allConditionsList } = props;
  let deseaseId = 0;
  if (window?.location?.href) {
    const params = new URL(window.location.href).searchParams;
    deseaseId = params.get("deseasId");
  }

  const deseaseDescription = allConditionsList[deseaseId] || [];
  const headerData = allConditionsList[0] || [];
  return (
    <Container className="specialty-details-main-container mt-2">
      <Row>
        <Col md={3} lg={3} sm={0} className="sticky-top bg-white">
          <LeftPanelContent
            {...props}
            deseaseDescription={deseaseDescription}
            headerData={headerData}
            deseaseId={deseaseId}
          />
        </Col>
        <Col md={9} lg={9} sm={0}>
          {headerData.map((eachData, index) => {
            if (deseaseDescription[index] !== "") {
              if (eachData.includes("Title")) {
                return (
                  <div id={`specialty-detail-${index}`}>
                    <Heading
                      className="specilty-details-title"
                      title={deseaseDescription[index]}
                    />
                  </div>
                );
              }
              if (eachData.includes("Description")) {
                return (
                  <TextComponent
                    className="specilty-details-description"
                    description={deseaseDescription[index]}
                  />
                );
              }
            }
          })}
        </Col>
      </Row>
    </Container>
  );
};

export default SpecialtyDetailPage;

SpecialtyDetailPage.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
  allConditionsList: PropTypes.object
};
