import React from "react";
import TextComponent from "../Common/TextComponent";
import Heading from "../Common/Heading";
import Langform from "../Common/LanguageAssistanceForm";
import { markedRequired } from "./constant";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const LanguageAssistanceModal = (props) => {
  const callbackdesc = getFieldValue(props.fields, "callbackdesc");
  const formtitle = getFieldValue(props.fields, "formtitle");
  const contactnumber = getFieldValue(props.fields, "contactnumber");
  const { emailInfo } = { ...props?.fields };

  return (
    <div
      id="contactForm"
      className="langAssistanceModal"
      data-testid="getModal"
    >
      {formtitle && (
        <Heading title={formtitle} className="langTitleModal"></Heading>
      )}
      {contactnumber && (
        <TextComponent
          description={contactnumber}
          className="contactLangAssistanceModal"
        />
      )}
      {callbackdesc && (
        <TextComponent
          description={callbackdesc}
          className="filltLangAssistanceModal"
        />
      )}
      <p className="formMandadoryDesc mt-3">{markedRequired}</p>
      <Langform emailInfo={emailInfo} />
    </div>
  );
};
export default LanguageAssistanceModal;
