/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import TextComponent from "../Common/TextComponent";
import Heading from "../Common/Heading";
import Langform from "../Common/LanguageAssistanceForm";
import CustomModal from "../Common/CustomModal";
import {
  requestCall_btn,
  markedRequired,
} from "../Common/LanguageAssistanceForm/Constant";
import { isMobile } from "../Common/utils";
import LanguageAssistanceModal from "./LanguageAssistanceModal";

const GettingStarted = (props) => {
  const { emailInfo } = { ...props?.fields };
  const [isMobileView, setIsMobileView] = useState(false);
  const isMobileViewData = isMobile();
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  const title = getFieldValue(props?.fields, "title");
  const { content } = { ...props?.fields };
  const formtitle = getFieldValue(props?.fields, "formtitle");
  const contactnumber = getFieldValue(props?.fields, "contactnumber");
  const callbackdesc = getFieldValue(props?.fields, "callbackdesc");
  const [show, setShowModal] = useState(false);

  const resetModal = () => {
    setShowModal(false);
  };

  return (
    <Container id="spjm-gettingStarted-container">
      <Row>
        <Col sm={12} md={7}>
          <Heading className="gettingStartedTitle" title={title} />
          {content?.map((list, index) => {
            const typevalue = list?.fields?.type?.value;
            const desctype = list?.fields?.desc?.value;
            return (
              ((typevalue === "languageDescription" && isMobileView) ||
                typevalue !== "languageDescription") && (
                <div
                  className={`text-content ${typevalue ===
                    "languageDescription" && "langAssistanceContact"}`}
                >
                  <TextComponent
                    className="gettingStartedContent"
                    description={desctype}
                  />
                  {list.fields.type.value === "languageDescription" && (
                    <Button
                      data-testid="showModalLang"
                      onClick={() => setShowModal(true)}
                      type="submit"
                      className="langAssistanceBtn d-block"
                    >
                      {requestCall_btn}
                    </Button>
                  )}
                </div>
              )
            );
          })}
          <CustomModal
            show={show}
            hideModal={resetModal}
            className="feedback-widget-modal"
          >
            <LanguageAssistanceModal {...props} />
          </CustomModal>
        </Col>
        <Col
          md={{ span: 4, offset: 1 }}
          className="langAssistanceForm d-none d-sm-block"
        >
          <div id="contactForm" className="contactForm" data-testid="getModal">
            <Heading title={formtitle} className="langTitle"></Heading>
            <TextComponent
              description={contactnumber}
              className="contactLangAssistance"
            />
            <TextComponent
              description={callbackdesc}
              className="filltLangAssistance"
            />
            <p className="formMandadoryDesc mt-3">{markedRequired}</p>
            <Langform emailInfo={emailInfo} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GettingStarted;

GettingStarted.propTypes = {
  fields: PropTypes.object,
};
