import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import ImageComponent from "../Common/ImageComponent";
import { isMobile } from "../Common/utils";

const CollaborativePartnerships = props => {
  const { content } = { ...props?.fields };
  const image = getFieldValue(props?.fields, "image");
  const imageMob = getFieldValue(props?.fields, "imageMob");
  const isMobileView = isMobile();

  return (
    <div id="unbiased-container" className="unbiased-container">
      <Container>
        <Row className="section-top section-bottom">
          <Col>
            {image && (
              <ImageComponent
                imgClassname="blogImg"
                imgUrl={isMobileView ? imageMob.src : image.src}
              />
            )}
          </Col>
          <Col>
            {content?.map((item, index) => {
              const title = item.fields.title.value;
              const description = item.fields.description.value;
              return(
              <div className="list-container" key={index}>
                <Heading title={title} className="subTitle" />
                <TextComponent
                  className="description"
                  description={description}
                />
              </div>
            )})}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CollaborativePartnerships;

CollaborativePartnerships.propTypes = {
  fields: PropTypes.object
};
