import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import ImageComponent from "../ImageComponent";
import TextComponent from "../TextComponent";
import Heading from "../Heading";

const Card = props => {
  const { imgUrl, description, title } = props?.data?.fields;
  const { imgClassname } = props;
  // const pageLocation = window.location.pathname;
  const pageLocation = props?.sitecoreContext?.route?.name?.trim();

  return (
    <div className="container">
      <div className="row justify-content-center py-3">
        <div
          id="cardImage"
          className={`col-lg-4 col-md-4 col-sm-8 text-center ${pageLocation ===
            "prescribers" && "col-lg-5 col-md-5 col-sm-8"}`}
        >
          <ImageComponent
            imgClassname={imgClassname}
            imgUrl={imgUrl?.value?.src}
          />
        </div>
        <div
          id="cardDescription"
          className={`col-lg-5 col-md-5 col-sm-8 ${pageLocation ===
            "prescribers" && "card-description col-lg-6 col-md-6 col-sm-8"}`}
        >
          {/* <p key={title.value}> */}
          <Heading title={title.value} className="article-subHeading" />
          {/* </p> */}
          <TextComponent
            className="articleDesc"
            key={description.value}
            description={description.value}
          />
        </div>
      </div>
    </div>
  );
};
export default withSitecoreContext()(Card);

Card.propTypes = {
  data: PropTypes.object,
  sitecoreContext: PropTypes.object,
  imgClassname: PropTypes.string
};
