import React from "react";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";

function OneColumnBlog(props) {
  const { title, description, themeColor } = { ...props.fields };
  const titlevalue = title?.value;
  const descriptionvalue = description?.value;
  return (
    <div id="blog-style" className="blog-style">
      <div className={themeColor?.value}>
        <Heading title={titlevalue} className='title' />
        <TextComponent className="description" description={descriptionvalue} />
      </div>
    </div>
  );
}

export default OneColumnBlog;

OneColumnBlog.propTypes = {
  fields: PropTypes.object
};
