/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
// import search_icon from '../../assets/images/search_icon.svg';
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { useDetectClickOutside } from "react-detect-click-outside";
import PropTypes from "prop-types";
import UseModalComponent from "../../hooks/UseModalComponent";

function Navigation(props) {
  const [modalState, setModalState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { itemList, image, LogoUrl, emailInfo } = {
    ...props?.navbarData,
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const currentPath =
    props.sitecoreContext.route.name.trim() === "home"
      ? "/" || ""
      : `/${props.sitecoreContext.route.name.trim()}`;

  const pathFunc = () => {
    itemList &&
      itemList.map((item, index) => {
        const contentval = item?.fields?.content;
        const urlval = item?.fields?.url?.value?.href;
        if (
          contentval?.length > 0 &&
          document?.getElementById(`basic-nav-dropdown-${index}`)
        ) {
          document.getElementById(`basic-nav-dropdown-${index}`).href = urlval;
        }
      });
  };

  useEffect(() => {
    pathFunc();
  });

  return (
    <>
      <Navbar
        fixed="top"
        id="spjm_navbar"
        expand="lg"
        className="spjm_navbar"
        expanded={isOpen}
      >
        <Container ref={ref} className="nav-main-container">
          <Navbar.Brand href={LogoUrl?.value?.href}>
            {image && <img src={image?.value?.src} alt="spjm_logo" />}
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="basic-navbar-nav bg-light"
            onClick={() => setIsOpen(!isOpen)}
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto navbar-list">
              {itemList &&
                itemList.length > 0 &&
                itemList.map((item, index) => {
                  const contentvalue = item?.fields?.content;
                  const titlevalue = item?.fields?.title?.value;
                  const urlvalue = item?.fields?.url?.value?.href;
                  const activeTab =
                    (currentPath === "/" && titlevalue === "Patient") ||
                    (currentPath !== "/" && urlvalue.includes(currentPath));
                  return (
                    <React.Fragment key={item.id}>
                      {contentvalue?.length > 0 ? (
                        <span className="first-child-navbar-wrapper">
                          <NavDropdown
                            id={`basic-nav-dropdown-${index}`}
                            title={titlevalue}
                            renderMenuOnMount={true}
                            className={`${activeTab && "active-item"}`}
                            data-testid={`basic-nav-dropdown-${index}`}
                          >
                            {item?.fields?.content?.map((list) => {
                              const titleval = list?.fields?.title?.value;
                              const urlval = list?.fields?.url?.value?.href;
                              return (
                                <NavDropdown.Item
                                  key={titleval}
                                  href={urlval}
                                  target={urlval?.includes("http") && "_blank"}
                                >
                                  {titleval}{" "}
                                </NavDropdown.Item>
                              );
                            })}
                          </NavDropdown>
                          {index === 0 && (
                            <div className="first-child-devider d-none d-lg-block"></div>
                          )}
                        </span>
                      ) : (
                        <span className={`${activeTab && "active-item"}`}>
                          <Nav.Link href={urlvalue} key={item?.id}>
                            {titlevalue}
                          </Nav.Link>
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
            </Nav>
            <span>
              <Button
                className="btn_contactus"
                data-testid="contactModal"
                onClick={() => setModalState(true)}
              >
                Contact Us
              </Button>
              {/* <img src={search_icon} alt="Search Icon" className='icon_search' /> */}
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <UseModalComponent
        show={modalState}
        hidemodal={setModalState}
        emailInfo={emailInfo}
      />
    </>
  );
}

export default withSitecoreContext()(Navigation);

Navigation.propTypes = {
  navbarData: PropTypes.object,
  sitecoreContext: PropTypes.object,
};
