import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  withSitecoreContext,
  getFieldValue
} from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import AccordionComponent from "../AccordionComponent";

const SpecialtyServices = props => {
  const { content } = { ...props?.fields };

  const heading = getFieldValue(props?.fields, "heading");

  const pageLocation = props.sitecoreContext.route.name;
  return (
    <div id="spjm-accordion-container" className="spjm-accordion-container">
      <div id="spjm-accordion-content-container" className="home">
        <div className={heading && content && "bg-image"}>
          <Container>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                {heading && (
                  <div className="accordion-section-heading">{heading}</div>
                )}
              </Col>
              <Col>
                {content && (
                  <AccordionComponent
                    accordionData={content}
                    emailInfo={
                      pageLocation === "prescribers" && props?.fields?.emailInfo
                    }
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
export default withSitecoreContext()(SpecialtyServices);

SpecialtyServices.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
