import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import window from "global";
import PropTypes from "prop-types";
import { isMobile } from "../Common/utils";

const LeftPanelContent = props => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { headerData, deseaseDescription } = props;
  const onTabClick = index => {
    const violation = document.getElementById(`specialty-detail-${index}`);
    setSelectedIndex(index);
    const headerHeight = isMobile() ? 190 : 100;
    window.scrollTo({
      top: violation.offsetTop - headerHeight,
      behavior: "smooth"
    });
  };
  return (
    <Container className="left-panel-description">
      <Row className="description-row pt-4">
        {headerData.map((eachData, index) => {
          if (deseaseDescription[index] !== "") {
            if (eachData.includes("Title")) {
              return (
                <Col
                  md={12}
                  lg={12}
                  sm={4}
                  xs={4}
                  data-testid={`tab-btn-${index}`}
                  className={`description-col`}
                  onClick={() => onTabClick(index)}
                >
                  <div
                    className={`description-inner-container ${index ===
                      selectedIndex && "selected-col"}`}
                  >
                    {deseaseDescription[index]}
                  </div>
                </Col>
              );
            }
          }
          return null;
        })}
      </Row>
    </Container>
  );
};

export default LeftPanelContent;

LeftPanelContent.propTypes = {
  headerData: PropTypes.object,
  deseaseDescription: PropTypes.object
};
