import React from "react";
import PropTypes from "prop-types";
import Navigation from "../Navigation";

const Header = props => (
  <div id="spjm-header-container" className="spjm-header-container">
    <Navigation navbarData={props?.fields} />
  </div>
);

export default Header;

Header.propTypes = {
  fields: PropTypes.object
};
