import React from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close_icon.svg";

const CustomModal = props => {
  const { hideModal } = props;
  return (
    <Modal {...props}>
      <Modal.Body>
        <Row className="cancel-button-container">
          <Col className="float-end">
            <div
              className="float-end cancel-button cursor-pointer"
              data-testid="cancel-btn"
              onClick={() => hideModal(false)}
            >
              <CloseIcon />
            </div>
          </Col>
        </Row>
        <Container>{props.children}</Container>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  children: PropTypes.object,
  hideModal: PropTypes.func
};
