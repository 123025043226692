import React from 'react';
import { Text, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import ImageComponent from '../Common/ImageComponent';
import { isMobile } from '../Common/utils';

const ImageContent = (props) => {
    const imageUrl = getFieldValue(props?.fields, 'imageUrl');
  const mobImageUrl = getFieldValue(props?.fields, 'mobImageUrl');
  const isMobileView = isMobile();
    return (
        <>
        {imageUrl && (<ImageComponent
          imgClassname="cd-image-container"
          imgUrl={isMobileView ? mobImageUrl.src : imageUrl.src} />)}
        </>    
    )
  }
  export default ImageContent;