import React from "react";
import PropTypes from "prop-types";

const ImageComponent = props => {
  const { imgClassname, alt } = props;
  const imgUrl = props.imgUrl;
  return (
    imgUrl && (
      <img
        className={imgClassname}
        src={props.imgUrl}
        alt={alt || "fairview"}
      />
    )
  );
};
export default ImageComponent;

ImageComponent.propTypes = {
  imgClassname: PropTypes.string,
  imgUrl: PropTypes.string,
  alt: PropTypes.string
};
