import React from "react";
import { Container, Carousel } from "react-bootstrap";
import PropTypes from "prop-types";

const CarouselBlog = props => {
  const { itemsList } = { ...props.fields };

  return (
    <div id="spjm-carousel-container" className="spjm-carousel-container">
      {
        <Carousel controls={false}>
          {itemsList &&
            itemsList.length > 0 &&
            itemsList.map((item, index) => {
              const imgUrl = item?.fields?.image?.value?.src;
              const titlevalue = item?.fields?.title?.value;
              const descriptionvalue = item?.fields?.description?.value;
              return (
                <Carousel.Item
                  interval={8000}
                  className="carousel-item-container"
                  key={item?.id}
                >
                  {imgUrl && (
                    <img
                      src={imgUrl}
                      className="d-block carousel-image"
                      alt={item.title}
                    />
                  )}

                  <Carousel.Caption>
                    <Container>
                      <div className="carousel-title-wrapper">
                        <div className="carousel-title">
                          {titlevalue}
                        </div>
                        <div className="carousel-description">
                          {descriptionvalue}
                        </div>
                      </div>
                    </Container>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
        </Carousel>
      }
    </div>
  );
};

export default CarouselBlog;

CarouselBlog.propTypes = {
  fields: PropTypes.object
};
