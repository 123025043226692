import React, { useState, useEffect } from "react";
import {
  getFieldValue,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";

import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import PropTypes from "prop-types";
import window from "global";
import Heading from "../Common/Heading";
import Accordion from "../Common/accordion";

const Faq = props => {
  const { content } = { ...props?.fields };
  const heading = getFieldValue(props?.fields, "heading");
  const accordionData = content ? content[0]?.fields?.content : [];
  const currentPath = `/${props?.sitecoreContext?.route?.name.trim()}`;
  const [activeKey, setActiveKey] = useState(null);
  let name = "";

  if (window?.location?.href) {
    const params = new URL(window?.location?.href).searchParams;
    name = params.get("name");
  }

  useEffect(() => {
    if (name) {
      setActiveKey(name?.toLowerCase());
    }
  }, [name]);

  const faqData = [];
  content.map(item => {
    if (item.fields.content.length > 0) {
      faqData.push(item);
    }
  });

  return (
    <div className="spjm-faqs">
      <div className="spjm-patient-resource">
        <Container>
          <Row className="section-top section-bottom">
            {currentPath === "/patient-resources" && (
              <Col>
                {heading && <Heading title={heading} className="faq-title" />}
                <div className="financial-advocate-accordion-container">
                  <Accordion accordionData={accordionData?.slice(0, 6)} />
                </div>
                <a href="/faq" className="faq-link">
                  {" "}
                  View more{" "}
                </a>
              </Col>
            )}
            <div className="financial-advocate-accordion-container">
              {currentPath === "/faq" && (
                <Tabs
                  className="controlled-tab-example"
                  activeKey={activeKey}
                  onSelect={tabSelect => {
                    setActiveKey(tabSelect);
                  }}
                >
                  {faqData?.length > 0 &&
                    faqData?.map((item, index) => {
                      const titlevalue = item?.fields?.title?.value;
                      const contentvalue = item.fields.content;
                      return (
                        <Tab
                          eventKey={titlevalue.toLowerCase()}
                          title={titlevalue}
                          key={index}
                        >
                          <Accordion accordionData={contentvalue} />
                        </Tab>
                      );
                    })}
                </Tabs>
              )}
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default withSitecoreContext()(Faq);

Faq.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
