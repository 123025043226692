import React from "react";
import { Field, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import TextError from "./TextError";

function Textarea(props) {
  const { label, name, placeholder, val, ...rest } = props;
  return (
    <div className="form-control">
      {val && <label htmlFor={name}>{label}</label>}
      <Field
        as="textarea"
        id={name}
        name={name}
        placeholder={placeholder}
        {...rest}
        maxlength={250}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Textarea;

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  val: PropTypes.string
};
