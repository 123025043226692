import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import PropTypes from "prop-types";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import UseModalComponent from "../../hooks/UseModalComponent";

const AccordionComponent = props => {
  const [modalState, setModalState] = useState(false);
  const currentPath = `/${props?.sitecoreContext?.route?.name.trim()}`;
  const defualtActive = currentPath === "/prescribers" ? 1 : 0;

  return (
    <div>
      <div>
        <Accordion defaultActiveKey={defualtActive}>
          {props.accordionData.map((item, index) => {
            const titlevalue = item.fields.title.value;
            const descriptionvalue = item.fields.description.value;
            return (
              <Accordion.Item
                key={item.id}
                eventKey={index === defualtActive ? defualtActive : index}
              >
                <Accordion.Header>{titlevalue}</Accordion.Header>
                <Accordion.Body>
                  <>
                    <span
                      dangerouslySetInnerHTML={{ __html: descriptionvalue }}
                    ></span>
                    {item.id === 106 && (
                      <span
                        data-testid="contact-us-btn"
                        onClick={() => setModalState(true)}
                        className="contact-us-prescriber"
                      >
                        Contact Us
                      </span>
                    )}
                  </>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
      {currentPath === "/prescribers" && (
        <UseModalComponent
          show={modalState}
          hidemodal={setModalState}
          emailInfo={props?.emailInfo}
        />
      )}
    </div>
  );
};
export default withSitecoreContext()(AccordionComponent);

AccordionComponent.propTypes = {
  emailInfo: PropTypes.object,
  sitecoreContext: PropTypes.object,
  accordionData: PropTypes.object,
};
