import React from "react";
import { Field, ErrorMessage } from "formik";
import PropTypes from "prop-types";
import TextError from "./TextError";

function Select(props) {
  const {
    label,
    name,
    placeholder,
    val,
    options,
    testid,
    wrapperClassName,
    ...rest
  } = props;
  return (
    <div className={`form-control ${wrapperClassName} `}>
      {val && <label htmlFor={name}>{label}</label>}
      <Field
        as="select"
        id={name}
        name={name}
        {...rest}
        placeholder={placeholder}
        data-testid="selectTag"
      >
        {options?.map((option, index) => (
          <option
            key={option?.value}
            value={option?.value}
            data-testid={`select-option-${index}-${testid}`}
          >
            {option?.key}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default Select;

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  val: PropTypes.string,
  testid: PropTypes.string,
  wrapperClassName: PropTypes.string,
  options: PropTypes.object
};
