/* eslint-disable arrow-body-style */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import window from "global";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
// import { isMobile } from "../Common/utils";
import stepArrow from "../../assets/images/step_arrow.svg";
import stepArrowDown from "../../assets/images/step_arrow_down.svg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import TextComponent from "../Common/TextComponent";

const isMobile = () => {
  return typeof window !== "undefined" && window.innerWidth < 800;
};

const WorkFlow = props => {
  const [isMobileView, setIsMobileView] = useState(false);
  const isMobileViewData = isMobile();
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);
  const myRef = useRef(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
  const { content, title } = { ...props?.fields };
  const allStepData = content;
  let pageLocation = "";
  pageLocation = window?.location?.pathname;

  const getArrowContent = () => {
    const arrowContent = [];
    for (let i = 0; i < allStepData?.length - 1; i++) {
      arrowContent.push(
        <Col
          xs={isMobileView && 12}
          sm={isMobileView && 12}
          key={`arrow-${i}`}
          className={`${isMobileView ? "my-auto position-relative" : "text-center"
            }`}
        >
          <img
            src={isMobileView ? stepArrowDown : stepArrow}
            className={`${isMobileView && "wf-arrow-img"}`}
          />
        </Col>
      );
    }
    return arrowContent;
  };
  const updateCurrentStepCounter = isIncrement => {
    if (isIncrement) {
      setCurrentStepIndex(currentStepIndex + 1);
    } else {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };
  const setBackClick = () => {
    return (
      <Row className="justify-content-center pt-4">
        <button
          className="wf-back-btn"
          onClick={() => {
            setCurrentStepIndex(null);
          }}
        >
          Back
        </button>
      </Row>
    );
  };
  const getCurrentStepData = currstepIndexMob => {
    const fromMobView = currstepIndexMob !== undefined;
    const currentStepIndexId = fromMobView
      ? currstepIndexMob
      : currentStepIndex;

    return (
      <Container data-testid="wf-description-container">
        <Row>
          {!fromMobView && (
            <Col
              md={2}
              className={
                pageLocation === "/prescribers" ? "text-center" : "text-end"
              }
            >
              {currentStepIndexId !== 0 && (
                <img
                  src={arrowLeft}
                  data-testid="wf-left-arrow"
                  className="wf-arrow-common cursor-pointer"
                  onClick={() => updateCurrentStepCounter()}
                />
              )}
            </Col>
          )}
          <Col md={8} className="text-center py-2">
            <div className="d-flex justify-content-center mb-2">
              <div className="wf-counter-container">
                {currentStepIndexId + 1}
              </div>
            </div>
            <Heading
              title={allStepData[currentStepIndexId]?.fields?.title?.value}
              className="wf-inner-heading"
            />
            <TextComponent
              description={
                allStepData[currentStepIndexId]?.fields?.description?.value
              }
              className="wf-inner-sub-heading"
            />
          </Col>
          {!fromMobView && (
            <Col
              md={2}
              className={
                pageLocation === "/prescribers" ? "text-center" : "text-start"
              }
            >
              {currentStepIndexId !== allStepData.length - 1 && (
                <img
                  src={arrowRight}
                  data-testid="wf-right-arrow"
                  className="wf-arrow-common cursor-pointer"
                  onClick={() => updateCurrentStepCounter(true)}
                />
              )}
            </Col>
          )}
        </Row>
        {!fromMobView && setBackClick()}
      </Container>
    );
  };
  const getCurrentStepDataMob = () => {
    return (
      <div className="wf-carousel-container">
        <Carousel
          controls={false}
          activeIndex={currentStepIndex}
          onSelect={selectedIndex => setCurrentStepIndex(selectedIndex)}
          interval={null}
        >
          {allStepData.map((eachStep, index) => {
            return (
              <Carousel.Item
                className="carousel-item-container"
                key={`carousel-item${index}`}
              >
                {getCurrentStepData(index)}
              </Carousel.Item>
            );
          })}
        </Carousel>
        {setBackClick()}
      </div>
    );
  };
  const getAllStepData = () => {
    return (
      <Container className="position-relative">
        <Row>
          {allStepData?.map((stepDataAll, index) => {
            const stepData = stepDataAll?.fields;
            return (
              <Col
                key={`step-img-${index}`}
                sm={isMobileView && 12}
                className="wf-icon-container"
              >
                <Row className={isMobileView && "py-5"}>
                  <Col lg={12} xs={4} sm={3} md={12}>
                    <div className="text-center">
                      <img
                        src={stepData?.imgUrl?.value?.src}
                        className="cursor-pointer"
                        data-testid={`custom-img-step-${index}`}
                        onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          isMobileView && myRef?.current?.scrollIntoView();
                          setCurrentStepIndex(index);
                        }}
                        height={110}
                        width={110}
                        alt="React Logo"
                      />
                    </div>
                  </Col>
                  <Col
                    lg={12}
                    xs={8}
                    sm={9}
                    md={12}
                    className={`${isMobileView && "my-auto"}`}
                  >
                    <div
                      className={`wf-lbl ${isMobileView ? "text-left" : "text-center"
                        }`}
                    >
                      {stepData.title.value}
                    </div>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
        {isMobileView && (
          <div className="wf-mob-line-container">
            <Row className="h-100">{getArrowContent()}</Row>
          </div>
        )}
        {!isMobileView && allStepData?.length && (
          <div
            className={`position-absolute ${pageLocation === "/prescribers"
              ? "wf-arrow-main-container-prescriber"
              : "wf-arrow-main-container"
              } w-100`}
          >
            <div className="position-relative">
              <Row className="wf-arrow-container">
                <div className="wf-line-container text-center w-100"></div>
              </Row>
              <Row className="wf-arrow-container wf-arrow-pos position-absolute w-100">
                {getArrowContent()}
              </Row>
            </div>
          </div>
        )}
      </Container>
    );
  };

  return (
    <div className="wf-main-container position-relative" ref={myRef}>
      <Heading title={title?.value} className="wf-heading-lbl" />
      {currentStepIndex === null && getAllStepData()}
      {currentStepIndex !== null && isMobileView && getCurrentStepDataMob()}
      {currentStepIndex !== null && !isMobileView && getCurrentStepData()}
    </div>
  );
};

export default WorkFlow;

WorkFlow.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
