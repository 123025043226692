import React, { useState } from "react";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import TextError from "./TextError";

function CheckboxGroup(props) {
  const { label, name, checkboxWrapperClass, ...rest } = props;
  const [checkVal, setCheckVal] = useState(false);

  const handleChange = () => {
    setCheckVal(!checkVal);
    props.onChange();
  };

  return (
    <div className={`form-control ${checkboxWrapperClass || ""}`}>
      <div>
        <label>{label}</label>
        <input
          className="spjm-uucheckbox"
          type="checkbox"
          id={name}
          {...rest}
          value={checkVal}
          checked={checkVal}
          onChange={handleChange}
          data-testid="feedback"
        />
      </div>

      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default CheckboxGroup;

CheckboxGroup.propTypes = {
  label: PropTypes.object,
  name: PropTypes.object,
  checkboxWrapperClass: PropTypes.string,
  onChange: PropTypes.func
};
