import React from "react";
import PropTypes from "prop-types";

const TextError = props => <div className="error"> {props.children} </div>;

export default TextError;

TextError.propTypes = {
  children: PropTypes.object
};
