import React, { useState, useEffect } from "react";
import {
  getFieldValue,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { isMobile } from "../Common/utils";
import Content from "./Content";
import ImageContent from "./ImageContent";

const ContentDescription = props => {
  const bgColor = getFieldValue(props?.fields, "bgColor");
  const imgAlign = getFieldValue(props?.fields, "imgAlign");
  const imgAlignMob = getFieldValue(props?.fields, "imgAlignMob");
  const [isMobileView, setIsMobileView] = useState(false);

  const isMobileViewData = isMobile();

  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  setTimeout(() => {
    if (window?.location?.hash) {
      const elementDestination = document.querySelector(window.location.hash);
      const currentHeight = elementDestination?.clientHeight - 30;
      window.scrollTo({
        top: currentHeight,
        behavior: "smooth"
      });
    }
  }, 200);

  return (
    <div
      id="ready-prescribers"
      className={`content-description-main-container ${bgColor === "gray" &&
        "gray-bg"} ${isMobileView ? "py-3" : "py-5"}`}
    >
      <Container>
        <Row>
          <Col
            md={6}
            lg={6}
            sm={12}
            xs={12}
            className={`px-4 py-4 m-auto 
            ${imgAlign === "right" && !isMobileView && "order-2"} 
            ${isMobileView && imgAlignMob === "bottom" && "order-2"}
            ${isMobileView && imgAlignMob !== "bottom" && "order-1"}
            `}
          >
            <ImageContent {...props} />
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="m-auto order-1">
            <Content {...props} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withSitecoreContext()(ContentDescription);

ContentDescription.propTypes = {
  fields: PropTypes.object,
  emailInfo: PropTypes.object,
  messageOpen: PropTypes.func,
  hidemodal: PropTypes.func
};
