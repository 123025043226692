import React from "react";
import { Container } from "react-bootstrap";
import {
  withSitecoreContext,
  getFieldValue
} from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import CarouselContent from "../Common/CarouselContent";
import ImageComponent from "../Common/ImageComponent";

const Testimonials = props => {
  const { content } = { ...props?.fields };

  const heading = getFieldValue(props.fields, "heading");
  const imgUrl = getFieldValue(props.fields, "imgUrl");

  const pageName = props?.sitecoreContext?.route?.name;
  const whiteBgPathArr = ["home", "manufacturers"];
  const isWhiteBg = whiteBgPathArr.indexOf(pageName) > -1;
  return (
    <Container
      className={
        isWhiteBg
          ? "testimonials-container-white"
          : "testimonials-container-gray"
      }
    >
      <div className="testimonials-section">
        {heading && (
          <Heading className="testimonials-heading" title={heading} />
        )}
        {imgUrl && (
          <ImageComponent
            imgClassname="testimonials-image"
            imgUrl={imgUrl.src}
          />
        )}
        {content && (
          <CarouselContent
            carouselcontentClassname="testimonials-carousel"
            carouselData={content}
          />
        )}
      </div>
    </Container>
  );
};

export default withSitecoreContext()(Testimonials);

Testimonials.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
