/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";
import FormikControl from "../Form/FormikControl";
import {
  e_prescriber_text,
  faxof_prescriber,
  phone_prescriber,
  submit,
  languageDropdown,
} from "./Constant";
import { getSgMail, allReplace, getLanguagesList } from "../Common/utils";

function ContactusForm(props) {
  const { to, from, subject, description } = { ...props?.emailInfo[0]?.fields };

  const [emailStatus, setEmailStatus] = useState("");
  const [language, setLanguage] = useState("");

  const initialValues = {
    userType:
      window.location.pathname === "/prescribers" ? "Prescriber" : "Patient",
    serviceRequest:
      window.location.pathname === "/fairview-link"
        ? "Fairview Link Message for secure online chat (current specialty or diabetes care patients only)"
        : "Call back",
    language: "",
    preferredLanguage: "",
    firstName: "",
    lastName: "",
    dob: "",
    phoneNumber: "",
    message: "",
    email: "",
    recaptchaToken: "",
  };

  const userType = [
    { key: "Patient", value: "Patient" },
    { key: "Prescriber", value: "Prescriber" },
    { key: "Payer", value: "Payer" },
    { key: "Manufacturer", value: "Manufacturer" },
    { key: "Other", value: "Other" },
  ];

  const patientDropdown = [
    { key: "Call back", value: "Call back" },
    {
      key: "Call back with non-English language interpreter",
      value: "Call back with non-English language interpreter",
    },
    {
      key:
        "Call back via telecommunication relay service (TRS) for hearing impaired patients",
      value:
        "Call back via telecommunication relay service (TRS) for hearing impaired patients",
    },
    {
      key:
        "Fairview Link Message for secure online chat (current specialty or diabetes care patients only)",
      value:
        "Fairview Link Message for secure online chat (current specialty or diabetes care patients only)",
    },
    {
      key: "E-mail (do not send patient health information)",
      value: "E-mail (do not send patient health information)",
    },
  ];

  const nonPatientDropdown = [
    { key: "Call back", value: "Call back" },
    {
      key: "E-mail (do not send patient health information)",
      value: "E-mail (do not send patient health information)",
    },
  ];

  const validateObj = {
    userType: Yup.string().required("This field is mandatory"),
    serviceRequest: Yup.string().required("This field is mandatory"),
    message: Yup.string().required("Message is required"),
    recaptchaToken: Yup.string().required("Captcha is required"),
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z' -]+$/, "Please enter alphabets only")
      .trim("Spaces not allow in First Name")
      .strict()
      .max(50, "First name maximum of 50 character can be used"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z' -]+$/, "Please enter alphabets only")
      .trim("Spaces not allow in Last Name")
      .strict()
      .max(50, "Last name maximum of 50 character can be used"),
  };

  emailStatus.includes("E-mail")
    ? (validateObj.email = Yup.string()
        .email("Please enter valid email address")
        .required("E-mail address is required"))
    : (validateObj.phoneNumber = Yup.string()
        .required("Phone number is required")
        .matches(/^(?!0+$)[0-9+-]+$/, "Please enter valid phone number")
        .max(15, "Phone Number maximum of 15 digits can be used")
        .min(7, "Please enter valid phone number"));
  emailStatus.includes("non-English")
    ? (validateObj.language = Yup.string().required("Language is required"))
    : delete validateObj.language;

  emailStatus.includes("non-English") && language.includes("Other")
    ? (validateObj.preferredLanguage = Yup.string().required(
        "Preferred Language is required"
      ))
    : delete validateObj.preferredLanguage;

  const validationSchema = Yup.object(validateObj);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const validateEmail = values.serviceRequest.includes("E-mail");

    if (validateEmail) {
      delete values.phoneNumber;
    } else {
      delete values.email;
    }

    let contactLanguage = null;
    if (values.language) {
      if (values.language === "Other") {
        contactLanguage = values.preferredLanguage;
      } else {
        contactLanguage = values.language;
      }
    } else {
      contactLanguage = "English";
    }

    const getDob = values.dob
      ? `${new Date(values.dob).getMonth() + 1}/${new Date(
          values.dob
        ).getDate()}/${new Date(values.dob).getFullYear()}`
      : "-";
    const phoneNumbe = values.phoneNumber ? values.phoneNumber : "-";
    const email = values.email ? values.email : "-";
    const messageField = values.message ? values.message : "-";

    const bodyDescription = allReplace(description.value, {
      "##todaydate": new Date(),
      "##username": values.userType,
      "##firstName": values.firstName,
      "##lastName": values.lastName,
      "##phoneNumbe": phoneNumbe,
      "##dob": getDob,
      "##email": email,
      "##contactLanguage": contactLanguage,
      "##message": messageField,
      "##serviceRequest": values.serviceRequest,
    });

    const message = {
      ToEmail: to.value,
      FromEmail: from.value,
      FromName: "",
      EmailSubject: allReplace(subject.value, {
        "##username": values.userType,
        "##serviceRequest": values.serviceRequest,
        "##lastName": values.lastName,
      }),
      EmailContent: bodyDescription,
    };

    getSgMail(message, "contacus", props.messageOpen, props.hidemodal, null);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => {
          const requestServiceDropdown =
            formik.values.userType === "Patient"
              ? patientDropdown
              : nonPatientDropdown;

          setEmailStatus(formik.values.serviceRequest);
          setLanguage(formik.values.language);
          return (
            <div
              id="contactForm"
              className="contactForm"
              data-testid="contact-us-modal"
            >
              <Form>
                <FormikControl
                  wrapperClassName={
                    formik.values.userType === "Prescriber" && "userType"
                  }
                  control="select"
                  label="I am a*"
                  name="userType"
                  options={userType}
                  placeholder="I am a*"
                  val={formik.values.userType}
                />

                {formik.values.userType === "Prescriber" && (
                  <div className="contact-prescriber">
                    <div className="contact-prescriber-firstchild">
                      {e_prescriber_text}
                    </div>
                    <div>{faxof_prescriber}</div>
                    <div>{phone_prescriber}</div>
                  </div>
                )}

                <FormikControl
                  control="select"
                  label="Return Service Request*"
                  name="serviceRequest"
                  options={requestServiceDropdown}
                  placeholder="Return Service Request*"
                  val={formik.values.serviceRequest}
                  testid="serviceRequest"
                />

                {formik.values.serviceRequest.includes("non-English") &&
                  formik.values.userType === "Patient" && (
                    <FormikControl
                      control="select"
                      label="Language*"
                      name="language"
                      options={getLanguagesList(props.languageList)}
                      placeholder="Language*"
                      val={formik.values.language}
                    />
                  )}

                {formik.values.serviceRequest.includes("non-English") &&
                  language === "Other" &&
                  formik.values.userType === "Patient" && (
                    <FormikControl
                      control="input"
                      label="Preferred Language*"
                      name="preferredLanguage"
                      placeholder="Preferred Language*"
                      type="text"
                      val={formik.values.preferredLanguage}
                    />
                  )}

                <FormikControl
                  control="input"
                  label="First Name*"
                  type="text"
                  placeholder="First Name*"
                  name="firstName"
                  val={formik.values.firstName}
                />

                <FormikControl
                  control="input"
                  label="Last Name*"
                  type="text"
                  placeholder="Last Name*"
                  name="lastName"
                  val={formik.values.lastName}
                />

                {formik.values.userType === "Patient" && (
                  <FormikControl
                    control="date"
                    label="Pick a date"
                    name="dob"
                    dateFormat="MM/dd/yyyy"
                    placeholder="DOB (mm/dd/yyyy)"
                    getDate={formik.values}
                  />
                )}

                {!formik.values.serviceRequest.includes("E-mail") && (
                  <FormikControl
                    control="input"
                    type="text"
                    label="Phone*"
                    placeholder="Phone*"
                    name="phoneNumber"
                    val={formik.values.phoneNumber}
                  />
                )}

                {formik.values.serviceRequest.includes("E-mail") && (
                  <FormikControl
                    control="input"
                    label="E-mail*"
                    type="email"
                    placeholder="E-mail*"
                    name="email"
                    val={formik.values.email}
                  />
                )}

                <FormikControl
                  val={formik.values.message}
                  label="Message or request*"
                  control="textarea"
                  name="message"
                  placeholder="Message or request*"
                />
                <Field type="hidden" name="recaptchaToken" />
                {props.reCaptchaKey && (
                  <ReCAPTCHA
                    sitekey={props.reCaptchaKey}
                    onChange={(token) =>
                      formik.setFieldValue("recaptchaToken", token)
                    }
                  />
                )}
                <ErrorMessage
                  name="recaptchaToken"
                  component={(_props) => (
                    <div className="error">Captcha is required</div>
                  )}
                />

                <button
                  className="submit-button mt-2"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {submit}
                </button>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
}

export default ContactusForm;

ContactusForm.propTypes = {
  fields: PropTypes.object,
  emailInfo: PropTypes.object,
  messageOpen: PropTypes.func,
  hidemodal: PropTypes.func,
  reCaptchaKey: PropTypes.string,
  languageList: PropTypes.object,
};
