import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import ImageComponent from "../Common/ImageComponent";

const PrImportanceResourcesBlog = props => {
  const { title, description, image } = { ...props.fields };
  const imgval = image?.value?.src;
  const titleval = title?.value;
  const descriptionval = description?.value;
  return (
    <div className="spjm-patient-resource">
      <div className="section-bottom">
        <Container className="importance-resource">
          <Row>
            <Col>
              <ImageComponent

                imgUrl={imgval}
              />
            </Col>

            <Col className='importance-resource-text-blog'>
              <Heading title={titleval} className='fa-title' />
              <TextComponent
                className="fv-description"
                description={descriptionval}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrImportanceResourcesBlog;

PrImportanceResourcesBlog.propTypes = {
  fields: PropTypes.object
};
