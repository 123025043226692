/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";
import TextComponent from "../Common/TextComponent";

const Recognition = props => {
  const { content } = { ...props?.fields };

  const title = getFieldValue(props.fields, "title");
  const description = getFieldValue(props.fields, "description");

  return (
    <Container
      className="spjm-recognition-container"
      id="spjm-recognition-container"
    >
      <Row>
        <Col xs={12} md={6} className="px-3">
          {title && (
            <Heading
              title={title}
              id="accreditations_and_recognition_title"
              className="accreditations_and_recognition_title pb-3"
            />
          )}
          {description && <TextComponent description={description} />}
        </Col>
        <Col xs={12} md={6} id="accreditations_awards">
          <Row>
            {content &&
              content.map((items, index) => {
                const { fields } = items;
                const urlval = fields?.url?.value;
                const imgurlval = fields?.imgUrl?.value?.src;
                const altText = fields?.imgUrl?.value?.alt;
                return (
                  <>
                    <Col
                      xs={6}
                      lg={3}
                      className="text-center pb-4"
                      key={fields.id}
                    >
                      <a target="_blank" href={urlval}>
                        <ImageComponent imgUrl={imgurlval} alt={altText} />
                      </a>
                    </Col>
                  </>
                );
              })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Recognition;

Recognition.propTypes = {
  fields: PropTypes.object
};
