import React, { useState } from "react";
import DateView from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "formik";
import TextError from "./TextError";

function DatePicker(props) {
  const { name, placeholder, dateFormat, getDate, ...rest } = props;
  const [dateVal, setDateVal] = useState("");

  const onChangeDate = e => {
    setDateVal(e);
    getDate.dob = e;
  };
  return (
    <div className="form-control data-picker-field" role="date">
      <DateView
        id={name}
        data-testid="dateval"
        {...rest}
        selected={dateVal}
        onChange={e => onChangeDate(e)}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        className="datepicker"
        autoComplete="off"
        maxDate={new Date()}
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        showMonthDropdown
        scrollableMonthDropdown
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}
export default DatePicker;

DatePicker.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.any,
  getDate: PropTypes.object
};
