import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import ImageComponent from "../Common/ImageComponent";

function AboutUs(props) {
  const title = getFieldValue(props.fields, "title");
  const aboutPharmacy = getFieldValue(props.fields, "aboutPharmacy");
  const aboutPharmacyDescription = getFieldValue(
    props.fields,
    "aboutPharmacyDescription"
  );
  const history = getFieldValue(props.fields, "history");
  const historyDescription = getFieldValue(props.fields, "historyDescription");
  const historyImg = getFieldValue(props.fields, "historyImg");
  const titleDescription = getFieldValue(props.fields, "titleDescription");
  // const aboutusPharmacyImg = getFieldValue(props.fields, "aboutusPharmacyImg");

  return (
    <div id="spjm-aboutus-container" className="spjm-aboutus-container">
      <Container>
        <Row className="ourmission-section">
          <Col sm={12}>
            {title && <Heading title={title} className="title" />}
            {titleDescription && (
              <TextComponent
                className="description"
                description={titleDescription}
              />
            )}
          </Col>
        </Row>
      </Container>

      <div className="ourHistory-section">
        <Container>
          <Row>
            <Col className="col-md-4">
              {historyImg && (
                <ImageComponent
                  imgClassname="imgBlog"
                  imgUrl={historyImg.src}
                />
              )}
            </Col>

            <Col className="padding-left-alignment col-md-8">
              {history && (
                <Heading title={history} className="title title-mobile" />
              )}
              {historyDescription && (
                <TextComponent
                  className="description"
                  description={historyDescription}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row className="aboutus-pharmacy-section">
          <div
            className="container-section"
          >
            {aboutPharmacy && (
              <Heading title={aboutPharmacy} className="title title-mobile" />
            )}
            {aboutPharmacyDescription && (
              <TextComponent
                className="description"
                description={aboutPharmacyDescription}
              />
            )}
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default AboutUs;

AboutUs.propTypes = {
  fields: PropTypes.object
};
