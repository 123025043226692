import React from "react";
import PropTypes from "prop-types";
import TextComponent from "../TextComponent";
import ImageComponent from "../ImageComponent";

function List(props) {
  const { list, listStyle, imgStyle } = props;
  return (
    <>
      {list?.map((item, index) => {
        const imgvalue = item?.fields?.img?.value?.src;
        const listvalue = item?.fields?.list?.value;
        return(
        <div className="d-flex" key={index}>
          <div className={imgStyle}>
            <ImageComponent imgUrl={imgvalue} />
          </div>
          <TextComponent
            className={listStyle}
            description={listvalue}
          />
        </div>
      )})}
    </>
  );
}

export default List;

List.propTypes = {
  list: PropTypes.object,
  listStyle: PropTypes.string,
  imgStyle: PropTypes.string
};
