/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { usePapaParse } from "react-papaparse";
import down_arrow from "../../assets/icons/down_arrow.svg";
import up_arrow from "../../assets/icons/up_arrow.svg";
import { isMobile } from "../Common/utils";
import { csvUrl } from "../Common/constants";
import {
  withSitecoreContext,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";

const ConditionsList = props => {
  const title = getFieldValue(props?.fields, "title");
  const description = getFieldValue(props?.fields, "description");
  const [isMobileView, setIsMobileView] = useState(false);
  const [counter, setCounter] = useState(8); // Initially show 8 conditions on mobile view
  const [conditionsList, setConditionsList] = useState([]);

  const scrollToListTop = () => {
    window.scrollTo({
      top: 400,
      behavior: "smooth",
    });
  };

  const { readRemoteFile } = usePapaParse();
  const cb = () => {
    return {
      complete: (results) => {
        if (!results.errors.length) {
          const allData = results.data;
          const titleArr = [];
          for (let i = 1; i < allData.length - 1; i++) {
            titleArr.push(allData[i][0]);
          }
          setConditionsList(titleArr);
        }
      },
    };
  };

  useEffect(() => {
    readRemoteFile(csvUrl, cb());
  }, []);
  const isMobileViewData = isMobile();
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  function viewMoreLess() {
    setCounter(counter === 8 ? conditionsList?.length : 8);
    if (counter === conditionsList?.length) scrollToListTop();
  }

  return (
    <div className="Conditions-List container py-5">
      <p className={`Conditions-List-Title ${isMobileView && "px-3"}`}>
        {title}
      </p>
      <p
        className={
          isMobileView
            ? "Conditions-List-Description px-3"
            : "Conditions-List-Description"
        }
      >
        {conditionsList.length ? (
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        ) : (
          ""
        )}
      </p>
      {isMobileView && (
        <div className="SpecialtyCond-List">
          <ul className="Conditions-List-Mobile px-3">
            {conditionsList.map((item, index) => {
              return index >= counter ? null : (
                <li
                  className="ConditionListItem"
                  key={`condition_${index}`}
                  data-testid={`mob_testid_${index}`}
                >
                  <span className="list-item">
                    <a
                      href={`/specialty-condition-details?deseasId=${index +
                        1}`}
                    >
                      {item}
                    </a>
                  </span>
                </li>
              );
            })}
          </ul>
          <div
            className="ViewMoreLink col-10 px-3"
            data-testid="viewLink"
            onClick={() => viewMoreLess()}
          >
            {counter === 8 ? (
              <span>
                <span className="expand-link">
                  View all Speciality Conditions
                </span>{" "}
                <img src={down_arrow} alt="down arrow" />
              </span>
            ) : (
              <span>
                <span className="expand-link">View less</span>
                <img src={up_arrow} alt="down arrow" />
              </span>
            )}
          </div>
        </div>
      )}
      {!isMobileView && (
        <div className="SpecialtyCond-List">
          <ul className="Conditions-List-Desktop  px-0">
            {conditionsList.map((item, index) => {
              return (
                <li
                  className="ConditionListItem"
                  key={`condition_${index}`}
                  data-testid={`testid_${index}`}
                >
                  <span className="list-item">
                    <a
                      href={`/specialty-condition-details?deseasId=${index +
                        1}`}
                    >
                      {item}
                    </a>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default withSitecoreContext()(ConditionsList);

ConditionsList.propTypes = {
  fields: PropTypes.object,
};
