import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import ImageComponent from "../Common/ImageComponent";
import { isMobile } from "../Common/utils";
import Heading from "../Common/Heading";

const OurValues = props => {
  const { title, imageUrl, imageUrlMob, content } = { ...props?.fields };
  const isMobileView = isMobile();
  const imageUrlMobvalue = imageUrlMob?.value?.src;
  const imageUrlvalue = imageUrl?.value?.src;

  return (
    <div className="our-values-main-container py-5">
      <Container>
        <Row>
          <Col
            md={5}
            lg={5}
            sm={12}
            xs={12}
            className={`m-auto ${isMobileView ? "px-4" : "px-5"}`}
          >
            <ImageComponent
              imgClassname="w-100 left-img"
              imgUrl={isMobileView ? imageUrlMobvalue : imageUrlvalue}
            />
          </Col>
          <Col md={7} lg={7} sm={12} xs={12} className="px-4">
            <Row>
              <Heading
                title={title?.value}
                className={`our-value-heading pb-4 ${isMobileView && "pt-4"}`}
              />
            </Row>
            <Row>
              {content?.map((eachData, index) => {
                const imgurlval = eachData?.fields?.imageUrl?.value?.src;
                const titleval = eachData?.fields?.title?.value;
                const subtitleval = eachData?.fields?.subTitle?.value;
                return(
                <Col md={6} lg={6} sm={12} xs={12} key={index}>
                  <Row className="py-2">
                    <Col md={3} lg={3} sm={2} xs={2} className="m-auto">
                      <ImageComponent
                        imgUrl={imgurlval}
                      />
                    </Col>
                    <Col md={9} lg={9} sm={10} xs={10}>
                      <div className="section-heading">
                        {titleval}
                      </div>
                      <div className="section-description">
                        {subtitleval}
                      </div>
                    </Col>
                  </Row>
                </Col>
              )})}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OurValues;

OurValues.propTypes = {
  fields: PropTypes.object
};
