import React, { useState } from "react";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import TextError from "./TextError";

function RadioButton(props) {
  // eslint-disable-next-line no-unused-vars
  const { label, name, options, ...rest } = props;
  const [radioVal, setradioVal] = useState(false);

  const handleChange = () => {
    setradioVal(!radioVal);
  };
  return (
    <div className="form-control">
      <div key={name}>
        <input
          type="radio"
          id={name}
          {...rest}
          value={radioVal}
          checked={radioVal}
          onChange={handleChange}
          data-testid="radioBtn"
        />
        <label htmlFor={name}>{name}</label>
      </div>

      <ErrorMessage name={name} component={TextError} />
    </div>
  );
}

export default RadioButton;

RadioButton.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.object
};
