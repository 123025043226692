import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import PropTypes from "prop-types";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import FormikControl from "../../Form/FormikControl";
import { callBackBtn, toastMessage } from "./Constant";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success_icon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross_icon.svg";
import {
  getSgMail,
  allReplace,
  getInfoByComponent,
  getLanguagesList,
} from "../utils";

const Langform = (props) => {
  const { to, from, subject, description } = {
    ...props?.emailInfo?.[0]?.fields,
  };
  const header = getInfoByComponent(props?.sitecoreContext, "Header");
  const languageList = getLanguagesList(header?.language);

  const [show, setShow] = useState(false);
  const closeBtn = () => {
    setShow(false);
  };

  const initialValues = {
    language: "",
    preferredLanguage: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };

  const validateObj = {
    preferredLanguage: Yup.string().when("language", {
      is: "Other",
      then: Yup.string().required("Language is required"),
      otherwise: Yup.string().notRequired(),
    }),
    language: Yup.string().required("Language is required"),
    firstName: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z' -]+$/, "Please enter alphabets only")
      .trim("Spaces not allow in First Name")
      .strict()
      .max(50, "First name maximum of 50 character can be used"),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z' -]+$/, "Please enter alphabets only")
      .trim("Spaces not allow in Last Name")
      .strict()
      .max(50, "Last name maximum of 50 character can be used"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .matches(/^(?!0+$)[0-9+-]+$/, "Please enter valid phone number")
      .max(15, "Phone Number maximum of 15 digits can be used")
      .min(7, "Please enter valid phone number"),
    email: Yup.string().email("Please enter valid email"),
  };

  const validationSchema = Yup.object(validateObj);

  const onSubmit = (values, { resetForm }) => {
    // resetForm({ initialValues })
    let contactLanguage = null;

    if (values.language === "Other") {
      contactLanguage = values.preferredLanguage;
    } else {
      contactLanguage = values.language;
    }

    const email = values.email ? values.email : "-";

    const subjectLine = allReplace(subject.value, {
      "##lastName": values.lastName,
      "##contactLanguage": contactLanguage,
    });

    const bodyDescription = allReplace(description.value, {
      "##todaydate": new Date(),
      "##firstName": values.firstName,
      "##lastName": values.lastName,
      "##phoneNumber": values.phoneNumber,
      "##email": email,
      "##contactLanguage": contactLanguage,
    });

    const message = {
      ToEmail: to.value,
      FromEmail: from.value,
      FromName: "",
      EmailSubject: subjectLine,
      EmailContent: bodyDescription,
    };

    getSgMail(message, "language", setShow, resetForm, initialValues);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form className="mt-4">
            <FormikControl
              control="select"
              placeholder="Language*"
              val={formik.values.language}
              label="Language*"
              name="language"
              options={languageList}
            />
            {formik.values.language.includes("Other") && (
              <FormikControl
                control="input"
                type="text"
                label="Preferred Language*"
                placeholder="Preferred Language*"
                name="preferredLanguage"
                val={formik.values.preferredLanguage}
              />
            )}
            <FormikControl
              control="input"
              label="First Name*"
              type="text"
              placeholder="First Name*"
              name="firstName"
              val={formik.values.firstName}
            />
            <FormikControl
              control="input"
              label="Last Name*"
              type="text"
              placeholder="Last Name*"
              name="lastName"
              val={formik.values.lastName}
            />
            <FormikControl
              control="input"
              label="Phone*"
              type="text"
              placeholder="Phone*"
              name="phoneNumber"
              val={formik.values.phoneNumber}
            />
            <FormikControl
              control="input"
              label="Email"
              type="email"
              placeholder="Email"
              name="email"
              val={formik.values.email}
            />
            <button className="submit-button" type="submit">
              {callBackBtn}
            </button>
          </Form>
        )}
      </Formik>
      <ToastContainer
        id="toast-container"
        className="p-3 start-50 translate-middle-x"
      >
        <Toast
          className="toast"
          show={show}
          onClose={() => setShow(false)}
          delay={5000}
          autohide
        >
          <Toast.Header closeButton={false} className="toast-header">
            <span className="me-2">
              <SuccessIcon />
            </span>
            <p>{toastMessage}</p>
            <span
              className="close-btn"
              data-testid="cross-btn"
              onClick={closeBtn}
            >
              <CrossIcon />
            </span>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default withSitecoreContext()(Langform);

Langform.propTypes = {
  emailInfo: PropTypes.object,
  sitecoreContext: PropTypes.object,
};
