import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";

const ManufacturerReporting = props => {
  const { content, title, linkUrl, linkText, themeBg } = { ...props?.fields };
  const currentpath = `${props?.sitecoreContext?.route?.name}`;

  return (
    <div className={`${themeBg?.value} manufacturer-reporting-main-container`}>
      <Heading title={title?.value} className="heading-lbl mb-4" />
      <Container>
        <Row>
          {content?.map((eachContent, index) => {
            const iconurlvalue = eachContent?.fields?.iconUrl?.value?.src;
            const descriptionvalue = eachContent?.fields?.description?.value;
            return(
            <Col
              md={currentpath === "payers" ? 4 : 6}
              sm={12}
              xs={12}
              className="d-flex my-2"
              key={index}
            >
              <ImageComponent
                imgUrl={iconurlvalue}
                imgClassname="manufacturer-reporting-image"
              />
              <div
                className={`mx-4 m-auto manufacturer-reporting-description ${currentpath ===
                  "payers" && "our-values-description"}`}
              >
                {descriptionvalue}
              </div>
            </Col>
          )})}
        </Row>
        {currentpath === "manufacturers" && (
          <Row>
            <Col className="mt-4">
              <a
                href={linkUrl?.value?.href}
                className="manufacturer-reporting-link mt-4"
              >
                {linkText?.value}
              </a>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default withSitecoreContext()(ManufacturerReporting);

ManufacturerReporting.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
