import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";
import TextComponent from "../Common/TextComponent";

const Assistance = props => {
  const title = getFieldValue(props.fields, "title");
  const imgUrl = getFieldValue(props.fields, "imgUrl");
  const content = getFieldValue(props.fields, "content");
  return (
    <Container className="assistance-container">
      <Row>
        <Col md="auto">
          {imgUrl && (
            <ImageComponent
              imgClassname="assist-image-section"
              imgUrl={imgUrl.src}
            />
          )}
        </Col>

        <Col className="assist-content-section">
          {title && (
            <Heading
              id="assist-heading"
              className="assist-heading"
              title={title}
            />
          )}

          {content && (
            <TextComponent
              className="assist-content-lbl"
              description={content}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Assistance;

Assistance.propTypes = {
  fields: PropTypes.object
};
