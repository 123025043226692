/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import TextComponent from "../Common/TextComponent";
import Heading from "../Common/Heading";
import Langform from "../Common/LanguageAssistanceForm";
import { getLanguagesList, isMobile } from "../Common/utils";
import CustomModal from "../Common/CustomModal";
import { fillForm_Btn, markedRequired } from "./constant";
import ImageComponent from "../Common/ImageComponent";
import LanguageAssistanceModal from "./LanguageAssistanceModal";

const RefillYourRx = (props) => {
  const { emailInfo } = { ...props?.fields };

  const { content } = { ...props?.fields };
  const [show, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const title = getFieldValue(props.fields, "title");
  const callbackdesc = getFieldValue(props.fields, "callbackdesc");
  const formtitle = getFieldValue(props.fields, "formtitle");
  const contactnumber = getFieldValue(props.fields, "contactnumber");
  const heading = getFieldValue(props.fields, "heading");
  const imgUrl = getFieldValue(props.fields, "imgUrl");
  const description = getFieldValue(props.fields, "description");
  const languageList = getLanguagesList(props.fields.languages);
  const [isMobileView, setIsMobileView] = useState(false);
  const isMobileViewData = isMobile();
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  const resetModal = () => {
    setShowModal(false);
    setShowSuccessModal(false);
  };
  return (
    <Container id="spjm-refillrx-container" className="spjm-refillrx-container">
      <Row>
        <Col sm={12} md={7}>
          {title && <Heading className="refillrx-title" title={title} />}
          {content &&
            content.map((list, index) => {
              const typeval = list.fields.type.value;
              const descriptionval = list.fields.desc.value;
              return (
                ((typeval === "languageDescription" && isMobileView) ||
                  typeval !== "languageDescription") && (
                  <div
                    className={`text-content ${typeval ===
                      "languageDescription" && "language-contact"}`}
                    key={index}
                  >
                    <TextComponent
                      className="refillrx-content"
                      description={descriptionval}
                    />
                    {list.fields.type.value === "languageDescription" && (
                      <Button
                        data-testid="showModalLang"
                        onClick={() => setShowModal(true)}
                        type="submit"
                        className="fill-form-btn d-block"
                      >
                        {fillForm_Btn}
                      </Button>
                    )}
                  </div>
                )
              );
            })}
          <Row>
            {heading && <Heading className="fv-link-title" title={heading} />}
            <Col md={{ span: 5, order: 2 }} className="order-md-2">
              {imgUrl && (
                <ImageComponent
                  imgClassname="fvlink-image"
                  imgUrl={imgUrl.src}
                />
              )}
            </Col>
            <Col md={{ span: 7, order: 1 }} className="fv-link-content">
              {description && (
                <TextComponent
                  className="refillrx-content"
                  description={description}
                />
              )}
            </Col>
          </Row>
          <CustomModal
            show={show}
            hideModal={resetModal}
            className="feedback-widget-modal"
          >
            <LanguageAssistanceModal {...props} languageList={languageList} />
          </CustomModal>
        </Col>
        <Col
          md={{ span: 4, offset: 1 }}
          className="langAssistanceForm d-none d-sm-block"
        >
          <div id="contactForm" className="contactForm" data-testid="getModal">
            {formtitle && (
              <Heading title={formtitle} className="langTitle"></Heading>
            )}
            {contactnumber && (
              <TextComponent
                description={contactnumber}
                className="contactLangAssistance"
              />
            )}
            {callbackdesc && (
              <TextComponent
                description={callbackdesc}
                className="filltLangAssistance"
              />
            )}
            <p className="formMandadoryDesc mt-3">{markedRequired}</p>
            <Langform emailInfo={emailInfo} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default RefillYourRx;

RefillYourRx.propTypes = {
  fields: PropTypes.object,
};
