import React from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";
import ImageComponent from "../ImageComponent";

const CarouselContent = props => {
  const { carouselData, carouselcontentClassname } = props;

  return (
    <div className={carouselcontentClassname}>
      <Carousel controls={false}>
        {carouselData.map((itemAll, index) => {
          const item = itemAll.fields;
          const titlevalue = item?.title?.value;
          const descriptionvalue = item.description.value;
          const namevalue = item?.name?.value;
          const imgUrlvalue = item?.imgUrl?.value;
          return (
            <Carousel.Item
              interval={10000}
              className="carousel-content-item-container"
              key={index}
            >
              {imgUrlvalue && (
                <ImageComponent
                  className="carousel-content-image"
                  imgUrl={imgUrlvalue}
                />
              )}
              <div className="carousel-content-title-wrapper">
                {titlevalue && (
                  <div className="carousel-content-title">
                    {titlevalue}
                  </div>
                )}
                {descriptionvalue && (
                  <div
                    className="carousel-content-description"
                    dangerouslySetInnerHTML={{ __html: descriptionvalue }}
                  ></div>
                )}
                {namevalue && (
                  <div
                    className="carousel-content-name"
                    dangerouslySetInnerHTML={{ __html: namevalue }}
                  ></div>
                )}
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
export default CarouselContent;

CarouselContent.propTypes = {
  carouselData: PropTypes.object,
  carouselcontentClassname: PropTypes.string
};
