import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import Select from "./Select";
import Textarea from "./Textarea";
import CheckboxGroup from "./CheckboxGroup";
import DatePicker from "./DatePicker";
import RadioButton from "./RadioButton";

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "textarea":
      return <Textarea {...rest} />;
    case "select":
      return <Select {...rest} />;
    case "radio":
      return <RadioButton {...rest} />;
    case "checkbox":
      return <CheckboxGroup {...rest} />;
    case "date":
      return <DatePicker {...rest} />;
    default:
      return null;
  }
}
export default FormikControl;

FormikControl.propTypes = {
  control: PropTypes.object
};
