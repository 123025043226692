/* eslint-disable camelcase */
import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import CustomModal from "../Common/CustomModal";
import {
  heading_lbl,
  sub_heading_lbl,
  checkbox_lbl,
  website_placeholder_lbl,
  feedback_success_sub_heading,
  feedback_lbl,
} from "./constants";
import FormikControl from "../Form/FormikControl";
import { ReactComponent as Thumbsup } from "../../assets/icons/contactus_thumbsup.svg";
import { ReactComponent as SmileyGood } from "../../assets/icons/smiley_good.svg";
import { ReactComponent as SmileyBad } from "../../assets/icons/smiley_bad.svg";
import { ReactComponent as SmileyFair } from "../../assets/icons/smiley_fair.svg";
import { ReactComponent as SmileyAverage } from "../../assets/icons/smiley_average.svg";
import { ReactComponent as SmileyExcellent } from "../../assets/icons/smiley_excellent.svg";
import { thankyou_text } from "../../hooks/Constants";
import { getSgMail, allReplace } from "../Common/utils";

const FeedbackWidget = (props) => {
  const { to, from, subject, description } = { ...props.fields };
  const [show, setShowModal] = useState(false);
  const [selectedRatingIndex, setRatingIndex] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const ratingArr = [
    { name: "Bad", svgComponent: SmileyBad },
    { name: "Fair", svgComponent: SmileyFair },
    { name: "Average", svgComponent: SmileyAverage },
    { name: "Good", svgComponent: SmileyGood },
    { name: "Excellent", svgComponent: SmileyExcellent },
  ];

  const initialValues = {
    firstName: "",
    phoneNumber: "",
    email: "",
    aboutWebsite: "",
  };

  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validateObj = {
    firstName: Yup.string()
      .when([], {
        is: () => checkboxValue,
        then: Yup.string()
          .required("Name is required")
          .trim("Spaces not allow in Name")
          .strict()
          .max(50, "Name maximum of 50 character can be used"),
        otherwise: Yup.string().notRequired(),
      })
      .matches(/^[a-zA-Z' -]+$/, "Please enter alphabets only")
      .trim("Spaces not allow in First Name")
      .strict()
      .max(50, "Name maximum of 50 character can be used"),
    phoneNumber: Yup.string()
      .matches(/^(?!0+$)[0-9+-]+$/, "Please enter valid phone number")
      .max(15, "Phone Number maximum of 15 digits can be used")
      .min(7, "Please enter valid phone number"),
    email: Yup.string()
      .when([], {
        is: () => checkboxValue,
        then: Yup.string().required("Email is required"),
        otherwise: Yup.string().notRequired(),
      })
      .email("Please enter valid email"),
  };
  const validationSchema = Yup.object(validateObj);

  const resetModal = () => {
    setShowModal(false);
    setRatingIndex(null);
    setCheckboxValue(false);
    setShowSuccessModal(false);
  };

  const onSubmit = (values) => {
    const checkboxStatus = checkboxValue ? "Yes" : "No";

    const phoneNumber = values.phoneNumber ? values.phoneNumber : "-";
    const email = values.email ? values.email : "-";
    const aboutWebsite = values.aboutWebsite ? values.aboutWebsite : "-";
    const selectedRating = selectedRatingIndex + 1;
    const firstName = values.firstName ? values.firstName : "-";

    const bodyDescription = allReplace(description.value, {
      "##firstName": firstName,
      "##phoneNumber": phoneNumber,
      "##email": email,
      "##selectedRatingIndex": selectedRating,
      "##aboutWebsite": aboutWebsite,
      "##checkboxStatus ": checkboxStatus,
    });

    const message = {
      ToEmail: to.value,
      FromEmail: from.value,
      EmailSubject: subject.value,
      FromName: "",
      EmailContent: bodyDescription,
    };

    getSgMail(message, "feedback", setShowSuccessModal, resetModal, null);
  };

  const getFormControls = () => (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <div id="contactForm" className="contactForm">
            <Form>
              <FormikControl
                control="textarea"
                type="text"
                placeholder={website_placeholder_lbl}
                label={website_placeholder_lbl}
                name="aboutWebsite"
                val={formik.values.aboutWebsite}
              />
              <FormikControl
                label={checkbox_lbl}
                control="checkbox"
                type="checkbox"
                className="feedback-checkbox"
                name="showFields"
                checkboxWrapperClass="widget-wrapper"
                options={[{ key: "", value: "showFields" }]}
                onChange={() => {
                  setCheckboxValue(!checkboxValue);
                }}
              />
              {checkboxValue && (
                <>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Name*"
                    name="firstName"
                    label="Name*"
                    val={formik.values.firstName}
                  />
                  <FormikControl
                    control="input"
                    type="email"
                    placeholder="E-mail*"
                    name="email"
                    label="E-mail*"
                    val={formik.values.email}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Phone"
                    name="phoneNumber"
                    label="Phone"
                    val={formik.values.phoneNumber}
                  />
                </>
              )}
              <div className="form-control">
                <button
                  className="submit-button-feedback"
                  data-testid="feedback-submit"
                  disabled={selectedRatingIndex === null}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );

  const getModalContent = () => (
    <div>
      <div className="feedback-heading">{heading_lbl}</div>
      <div className="feedback-sub-heading">{sub_heading_lbl}</div>
      <Row className="feedback-icon-container py-4">
        {ratingArr.map((eachRatingData, index) => (
          <Col
            className={`px-1 feedback-icon ${selectedRatingIndex === index &&
              "feedback-selected-icon"}`}
            data-testid={`feedback-rating-${index}`}
            onClick={() => setRatingIndex(index)}
            key={index}
          >
            <div className="text-center">
              {React.createElement(eachRatingData.svgComponent)}
            </div>
            <div className="text-center">{eachRatingData.name}</div>
          </Col>
        ))}
      </Row>
      {getFormControls()}
    </div>
  );
  const getSuccessContent = () => (
    <Container>
      <div className="success-modal-container">
        <div>
          <div className="thumbpsup-img-wrapper">
            <Thumbsup />
          </div>
          <div className="success-text-container text-center">
            <div className="thankyou-text">{thankyou_text}</div>
            <div className="intimation-text">
              {feedback_success_sub_heading}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
  return (
    <Container>
      <div className="feedback-widget-container">
        <div
          className="wd-main-btn"
          data-testid="showModalBtn"
          onClick={() => setShowModal(true)}
        >
          {feedback_lbl}
        </div>
        <CustomModal
          show={show}
          dialogClassName={showSuccessModal && "success-feedback-modal"}
          hideModal={resetModal}
          className="feedback-widget-modal"
        >
          {showSuccessModal ? getSuccessContent() : getModalContent()}
        </CustomModal>
      </div>
    </Container>
  );
};

export default FeedbackWidget;

FeedbackWidget.propTypes = {
  fields: PropTypes.object,
};
