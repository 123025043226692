import Axios from "axios";
import window from "global";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
const CryptoJS = require("crypto-js");

// const sgMail = require("@sendgrid/mail");

// eslint-disable-next-line arrow-body-style
export const isMobile = () => {
  return typeof window !== "undefined" && window.innerWidth < 576;
};

export const getSgMail = (
  message,
  type,
  messageOpen,
  hidemodal,
  initialValues
) => {
  // sgMail.setApiKey('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');
  // sgMail
  //   .send(message)

  Axios.post(`/api/email/FSPSendMail`, message)
    .then((res) => {
      if (type === "contacus") {
        messageOpen(false);
        setTimeout(() => {
          hidemodal(false);
          messageOpen(true);
        }, 3500);
      } else if (type === "feedback") {
        messageOpen(true);
        setTimeout(() => {
          hidemodal();
        }, 3500);
      } else {
        messageOpen(true);
        hidemodal(initialValues);
      }
    })
    .catch((error) => {
      console.error("Error: ", error);
      // hidemodal(false);
    });
};

export const allReplace = (str, obj) => {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const x in obj) {
    // eslint-disable-next-line no-param-reassign
    str = str.replace(new RegExp(x, "g"), obj[x]);
  }
  return str;
};

export const getInfoByComponent = (siteCoreContext = {}, name) => {
  if (!siteCoreContext) return null;
  const placeHolders = siteCoreContext?.route?.placeholders?.["jss-main"];
  if (placeHolders) {
    const component = placeHolders.find(
      (placeholder) => placeholder.componentName === name
    );
    if (!component) return null;
    return component?.fields;
  }
  return null;
};

export const getLanguagesList = (languages = []) =>
  [{ key: "Please select a Language*", value: "" }].concat(
    languages?.map((language) => ({
      key: getFieldValue(language.fields, "description"),
      value: getFieldValue(language.fields, "counterValue"),
    }))
  );

export const encryptWithAES = (text) => {
  const passphrase = "123";
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext) => {
  const passphrase = "123";
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
