import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";

const Tiles = props => {
  const { itemsList } = { ...props?.fields };
  const currentpath = `/${props?.sitecoreContext?.route?.name}`;
  return (
    <Container id="spjm-quickAccessCard-container">
      <Row className={`card-row ${currentpath == '/manufacturers' && 'card-manufacture-row'} ${currentpath == '/payers' && 'card-manufacture-row'}`}>
        {itemsList?.length > 0 && itemsList?.map((item, index) => {
          const imgval = item?.fields?.image?.value?.src;
          const urlval = item?.fields?.url?.value?.href;
          const nameval = item?.fields?.name?.value;
          return (
            <React.Fragment key={item.id}>
              <Col lg={3} md={3} sm={6} xs={6} className="mb-4 mb-md-0" key={imgval}>
                <div className="tiles-wrapper">
                  <div className="tiles_border" >
                    <a href={urlval} className="text-decoration-none" >
                      <div className="d-flex justify-content-center align-items-center flex-column tiles">
                        <img src={imgval}></img>
                        <h3 id="spjm_tiles_titles" className="mt-4">{nameval}</h3>

                      </div>
                    </a>
                  </div>
                </div>
              </Col>
            </React.Fragment>
          )
        })}
      </Row>
    </Container>
  );
};

export default withSitecoreContext()(Tiles);

Tiles.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
