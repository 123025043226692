import React from "react";
import PropTypes from "prop-types";

const TextComponent = props => {
  const { className } = props;
  return (
    <div
      className={`${className || "articleDesc"}`}
      dangerouslySetInnerHTML={{ __html: props.description }}
    ></div>
  );
};
export default TextComponent;

TextComponent.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string
};
