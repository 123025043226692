import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import List from "../Common/List/List";

const RareDisease = (props) => {

  const { title, subDescription, rareDiseaseList, credentialTitle, credentialList } = { ...props.fields };
  const titleval = title?.value;
  const descriptionval = subDescription?.value;
  const credtitleval = credentialTitle?.value;
  return (
    <div id="rareDisease" className="rareDisease">
      <div className="section-container">
        <Container>
          <Row >
            <Col className='disease-center d-flex align-items-center col-lg-5'>
              <div className='disease-center-container'>
                <Heading title={titleval} className='title title-left-align' />
                <TextComponent className="description title-left-align" description={descriptionval} />
              </div>
            </Col>
            <Col className="col-lg-7">
              <Row>
                <Col className="rare-disease-container">
                  <List
                    list={rareDiseaseList}
                    listStyle="listStyle"
                    imgStyle="imgStyle"
                  />
                </Col>
                <Col className="credentials-container d-flex align-items-center">
                  <div>
                    <Heading title={credtitleval} className='credential-heading' />
                    <List list={credentialList} listStyle='listStyle' imgStyle='imgStyle' />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default RareDisease;

RareDisease.propTypes = {
  fields: PropTypes.object
};
