/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import {
  getFieldValue,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";

import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import { isMobile } from "../Common/utils";
import UseModalComponent from "../../hooks/UseModalComponent";

const FvLink = (props) => {
  const { content, emailInfo } = { ...props?.fields };
  const imgUrl = getFieldValue(props?.fields, "imgUrl");
  const [modalState, setModalState] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const isMobileViewData = isMobile();
  useEffect(() => {
    setIsMobileView(isMobileViewData);
  }, [isMobileViewData]);

  return (
    <div id="spjm-fv-link-container">
      <Container>
        <Row>
          <Col md={7}>
            {content &&
              content.map((contents, index) => {
                const descriptionvalue = contents.fields.description.value;
                const typevalue = contents.fields.type.value;
                const titlevalue = contents.fields.title.value;
                return (
                  <>
                    {titlevalue !== "" && (
                      <Heading className="heading" title={titlevalue} />
                    )}
                    <div className="content">
                      <div
                        className={`${typevalue === "true" &&
                          "fv-link-content"}`}
                      >
                        {typevalue === "true" && (
                          <div className="pb-4">
                            <h5 className="fv-patient">
                              {getFieldValue(
                                contents?.fields,
                                "fv_patient_title"
                              )}
                            </h5>
                            <span
                              className="enroll-btn"
                              onClick={() => setModalState(true)}
                              data-testid="getEnrollment"
                            >
                              {getFieldValue(
                                contents?.fields,
                                "fv_patient_description"
                              )}
                            </span>
                          </div>
                        )}
                        <TextComponent
                          className="fv-link-contents"
                          description={descriptionvalue}
                        />
                      </div>
                    </div>
                    {index === 0 && isMobileView && (
                      <div className="d-flex align-items-start justify-content-center mb-5">
                        <img src={imgUrl.src} alt="Fairview Link Mobile" />
                      </div>
                    )}
                  </>
                );
              })}
          </Col>
          <Col
            md={5}
            className="d-flex align-items-start justify-content-center p-4"
          >
            {!isMobileView && imgUrl?.src && (
              <img
                src={imgUrl.src}
                alt="Fairview Link Mobile"
                className="fv-link-img"
              />
            )}
          </Col>
        </Row>
      </Container>
      <UseModalComponent
        show={modalState}
        hidemodal={setModalState}
        emailInfo={emailInfo}
      />
    </div>
  );
};
export default withSitecoreContext()(FvLink);

FvLink.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
};
