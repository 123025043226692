import React from "react";
import Accordion from "react-bootstrap/Accordion";
import PropTypes from "prop-types";

const AccordionComponent = props => (
  <div className={props?.className}>
    <Accordion defaultActiveKey="0">
      {props.accordionData &&
        props.accordionData.map((item, index) => {
          const titlevalue = item.fields.title.value;
          const descriptionvalue = item.fields.description.value;
          return(
          <Accordion.Item key={item.id} eventKey={index}>
            <Accordion.Header>{titlevalue}</Accordion.Header>
            <Accordion.Body
              dangerouslySetInnerHTML={{
                __html: descriptionvalue
              }}
            ></Accordion.Body>
          </Accordion.Item>
        )})}
    </Accordion>
  </div>
);
export default AccordionComponent;

AccordionComponent.propTypes = {
  accordionData: PropTypes.object,
  className: PropTypes.string
};
