import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import ImageComponent from "../Common/ImageComponent";
import TextComponent from "../Common/TextComponent";

const Convenience = props => {
  const title = getFieldValue(props?.fields, "title");
  const { content } = { ...props?.fields };
  const imgUrl = getFieldValue(props?.fields, "imgUrl");

  return (
    <Container
      id="spjm-convenience-container"
      className="spjm-convenience-container"
    >
      <Row className="convenience-section">
        <Col className="convenience-image">
          {title && <Heading className="convenience-heading-mobile" title={title} />}
          {imgUrl && (
            <ImageComponent imgClassname="image-section" imgUrl={imgUrl.src} />
          )}
        </Col>
        <Col className="content-section">
          {title && <Heading className="convenience-heading" title={title} />}
          {content && (
            <TextComponent
              className="convenience-content-lbl"
              description={content.value}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Convenience;

Convenience.propTypes = {
  fields: PropTypes.object
};
