import React, { useState, useEffect } from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import { usePapaParse } from "react-papaparse";
import SpecialtyDetailPage from "./SpecialtyDetailPage";
import { csvUrl } from "../Common/constants";

const SpecialtyServicesDetails = props => {
  const [allConditionsList, setConditionsList] = useState([]);
  const { readRemoteFile } = usePapaParse();
  useEffect(() => {
    readRemoteFile(csvUrl, {
      complete: results => {
        if (!results.errors.length) {
          const allData = results.data;
          setConditionsList(allData);
        }
      }
    });
  }, []);
  return (
    <div>
      <SpecialtyDetailPage {...props} allConditionsList={allConditionsList} />
    </div>
  );
};

export default withSitecoreContext()(SpecialtyServicesDetails);

SpecialtyServicesDetails.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object
};
