import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Heading from "../Common/Heading";
import TextComponent from "../Common/TextComponent";
import { isMobile } from "../Common/utils";
import ImageComponent from "../Common/ImageComponent";

const PatientDeliveryService = props => {
  const { title, description, image } = { ...props?.fields };
  const isMobileView = isMobile();
  const imgval = image?.value?.src;
  const titleval = title?.value;
  const descriptionval = description?.value;

  return (
    <div className="spjm-patient-resource">
      <div className="ourDeliveryServiceBlog">
        <Container>
          <Row>
            <Col className='blog-parent'>
              <ImageComponent imgClassname='blog-child'
                imgUrl={imgval}
              />
            </Col>
            <Col className={isMobileView && 'section-top'}>
              <Heading title={titleval} className='fa-title' />
              <TextComponent
                className="fv-description"
                description={descriptionval}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PatientDeliveryService;

PatientDeliveryService.propTypes = {
  fields: PropTypes.object
};
