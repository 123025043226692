/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "react-bootstrap/Container";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";

function Footer(props) {
  const { socialIcons, footerList } = { ...props?.fields };

  const urls = [
    "https://www.facebook.com/mhealthfairview",
    "https://www.instagram.com/mhealthfairview/",
    "https://twitter.com/mhealthfairview",
    "https://www.youtube.com/user/HealthEast/featured",
  ];

  const footerLogo = getFieldValue(props.fields, "footerLogo");
  const scrollTopIcon = getFieldValue(props.fields, "scrollTopIcon");
  const logoUrl = getFieldValue(props.fields, "logoUrl");
  const address = getFieldValue(props.fields, "address");

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="spjm_footer">
      <Container>
        <div className="row">
          <div
            id="spjm_footerlogo_container"
            className="col-lg-3 col-md-6 col-sm-6 py-3"
          >
            <div className="spjm_footer_logo">
              {footerLogo && address && (
                <>
                  <a href={logoUrl.href}>
                    <img src={footerLogo.src} alt="spjm_footer_logo" />
                  </a>
                  <div
                    className="address-text text-white"
                    dangerouslySetInnerHTML={{ __html: address }}
                  ></div>
                </>
              )}
            </div>
            <div className="spjm_socialmedia" id="spjm_socialmedia">
              {socialIcons &&
                socialIcons.map((item, index) => {
                  const imagevalue = item.fields.image.value.src;
                  return (
                    <span
                      className="spjm_social_media_icon"
                      key={`${item}-${index}`}
                    >
                      <a href={urls[index]} target="_blank" rel="noreferrer">
                        <img src={imagevalue} alt={item} />
                      </a>
                    </span>
                  );
                })}
            </div>
          </div>
          {footerList &&
            footerList.map((item) => {
              const contentvalue = item?.fields?.content;
              const footertitle = item?.fields?.title?.value;
              return (
                <div
                  id="spjm_footer_content"
                  className="col-lg-3 col-md-6 col-sm-6 py-3 spjm_footer_content"
                  key={item.id}
                >
                  <div className="spjm_footer_title">{footertitle}</div>
                  <ul>
                    {contentvalue?.length > 0 &&
                      contentvalue.map((list, index) => {
                        const titlevalue = list?.fields?.title?.value;
                        const urlvalue = list?.fields?.url?.value?.href;
                        return (
                          <li key={`footer_${index}`} data-testid="todo">
                            <a
                              href={urlvalue}
                              target={urlvalue?.includes("http") && "_blank"}
                            >
                              {titlevalue}
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </div>

        <div
          id="spjm_scrollTop"
          data-testid="element-scrollTop"
          role="tablist"
          onClick={goToTop}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Scroll To Top"
        >
          {scrollTopIcon && (
            <img id="img_scrollTop" src={scrollTopIcon.src} alt="scrolltop" />
          )}
        </div>

        <div id="spjm_devider_containe" className="spjm_devider_container">
          <hr className="spjm_devider_color" />
        </div>

        <div id="spjm_footer_copyrights" className="spjm_footer_copyrights">
          <span>&copy;</span>
          <span> {new Date().getFullYear()}</span>
          <span> Fairview Health Services. All rights reserved</span>
        </div>
      </Container>
    </div>
  );
}

export default Footer;

Footer.propTypes = {
  fields: PropTypes.object,
};
